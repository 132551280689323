export const ITEMS_ACTIONS = {
    requesting: 'ITEMS_REQUESTING',
    received: 'ITEMS_RECEIVED',
    request_failed: 'ITEMS_REQUEST_FAILED',
    added: 'ITEM_ADDED',
    added_to_bottom: 'ITEM_ADDED_TO_BOTTOM',
    updated: 'ITEM_UPDATED',
    deleted: 'ITEM_DELETED',
    invalidated: 'ITEMS_INVALIDATED',
    reset_new_items_counter: 'ITEMS_NEW_COUNTER_RESET',
}

export const ORGS_ACTIONS = {
    requesting: 'ORGS_REQUESTING',
    received: 'ORGS_RECEIVED',
    request_failed: 'ORGS_REQUEST_FAILED',
    added: 'ORG_ADDED',
    added_to_bottom: 'ORG_ADDED_TO_BOTTOM',
    updated: 'ORG_UPDATED',
    deleted: 'ORG_DELETED',
    invalidated: 'ORGS_INVALIDATED'
}

export const TO_CONFIRM_ORGS_ACTIONS = {
    requesting: 'TO_CONFIRM_ORGS_REQUESTING',
    received: 'TO_CONFIRM_ORGS_RECEIVED',
    request_failed: 'TO_CONFIRM_ORGS_REQUEST_FAILED',
    added: 'TO_CONFIRM_ORG_ADDED',
    added_to_bottom: 'TO_CONFIRM_ORG_ADDED_TO_BOTTOM',
    updated: 'TO_CONFIRM_ORG_UPDATED',
    deleted: 'TO_CONFIRM_ORG_DELETED',
    invalidated: 'TO_CONFIRM_ORGS_INVALIDATED'
}

export const OKRS_ACTIONS = {
    requesting: 'OKRS_REQUESTING',
    received: 'OKRS_RECEIVED',
    request_failed: 'OKRS_REQUEST_FAILED',
    added: 'OKR_ADDED',
    updated: 'OKR_UPDATED',
    deleted: 'OKR_DELETED',
    invalidated: 'OKRS_INVALIDATED'
}

export const ALIGNED_OKRS_ACTIONS = {
    requesting: 'ALIGNED_OKRS_REQUESTING',
    received: 'ALIGNED_OKRS_RECEIVED',
    request_failed: 'ALIGNED_OKRS_REQUEST_FAILED',
    added: 'ALIGNED_OKR_ADDED',
    updated: 'ALIGNED_OKR_UPDATED',
    deleted: 'ALIGNED_OKR_DELETED',
    invalidated: 'ALIGNED_OKRS_INVALIDATED'
}

export const GROUP_OKRS_ACTIONS = {
    requesting: 'GROUP_OKRS_REQUESTING',
    received: 'GROUP_OKRS_RECEIVED',
    request_failed: 'GROUP_OKRS_REQUEST_FAILED',
    added: 'GROUP_OKR_ADDED',
    updated: 'GROUP_OKR_UPDATED',
    deleted: 'GROUP_OKR_DELETED',
    invalidated: 'GROUP_OKRS_INVALIDATED'
}

export const COMPANY_OKRS_ACTIONS = {
    requesting: 'COMPANY_OKRS_REQUESTING',
    received: 'COMPANY_OKRS_RECEIVED',
    request_failed: 'COMPANY_OKRS_REQUEST_FAILED',
    added: 'COMPANY_OKR_ADDED',
    updated: 'COMPANY_OKR_UPDATED',
    deleted: 'COMPANY_OKR_DELETED',
    invalidated: 'COMPANY_OKRS_INVALIDATED'
}

export const PEOPLE_OKRS_ACTIONS = {
    requesting: 'PEOPLE_OKRS_REQUESTING',
    received: 'PEOPLE_OKRS_RECEIVED',
    request_failed: 'PEOPLE_OKRS_REQUEST_FAILED',
    added: 'PEOPLE_OKR_ADDED',
    updated: 'PEOPLE_OKR_UPDATED',
    deleted: 'PEOPLE_OKR_DELETED',
    invalidated: 'PEOPLE_OKRS_INVALIDATED'
}

export const GROUPS_ACTIONS = {
    requesting: 'GROUPS_REQUESTING',
    received: 'GROUPS_RECEIVED',
    request_failed: 'GROUPS_REQUEST_FAILED',
    added: 'GROUP_ADDED',
    added_to_bottom: 'GROUP_ADDED_TO_BOTTOM',
    updated: 'GROUP_UPDATED',
    deleted: 'GROUP_DELETED',
    invalidated: 'GROUPS_INVALIDATED'
}

export const FOLLOWERS_ACTIONS = {
    requesting: 'FOLLOWERS_REQUESTING',
    received: 'FOLLOWERS_RECEIVED',
    request_failed: 'FOLLOWERS_REQUEST_FAILED',
    added: 'FOLLOWER_ADDED',
    updated: 'FOLLOWER_UPDATED',
    deleted: 'FOLLOWER_DELETED',
    invalidated: 'FOLLOWERS_INVALIDATED'
}

export const THREAD_MEMBERS_ACTIONS = {
    requesting: 'THREAD_MEMBERS_REQUESTING',
    received: 'THREAD_MEMBERS_RECEIVED',
    request_failed: 'THREAD_MEMBERS_REQUEST_FAILED',
    added: 'THREAD_MEMBER_ADDED',
    updated: 'THREAD_MEMBER_UPDATED',
    deleted: 'THREAD_MEMBER_DELETED',
    invalidated: 'THREAD_MEMBERS_INVALIDATED'
}

export const POSTS_ACTIONS = {
    requesting: 'POSTS_REQUESTING',
    received: 'POSTS_RECEIVED',
    request_failed: 'POSTS_REQUEST_FAILED',
    added: 'POST_ADDED',
    updated: 'POST_UPDATED',
    deleted: 'POST_DELETED',
    invalidated: 'POSTS_INVALIDATED'
}

export const THREADS_ACTIONS = {
    requesting: 'THREADS_REQUESTING',
    received: 'THREADS_RECEIVED',
    request_failed: 'THREADS_REQUEST_FAILED',
    added: 'THREAD_ADDED',
    updated: 'THREAD_UPDATED',
    deleted: 'THREAD_DELETED',
    invalidated: 'THREAD_INVALIDATED'
}

export const RILS_ACTIONS = {
    requesting: 'RILS_REQUESTING',
    received: 'RILS_RECEIVED',
    request_failed: 'RILS_REQUEST_FAILED',
    added: 'RIL_ADDED',
    updated: 'RIL_UPDATED',
    deleted: 'RIL_DELETED',
    invalidated: 'RILS_INVALIDATED'
}

export const DOCS_ACTIONS = {
    requesting: 'DOCS_REQUESTING',
    received: 'DOCS_RECEIVED',
    request_failed: 'DOCS_REQUEST_FAILED',
    added: 'DOC_ADDED',
    updated: 'DOC_UPDATED',
    deleted: 'DOC_DELETED',
    invalidated: 'DOCS_INVALIDATED'
}

export const FLOW_DOCS_ACTIONS = {
    requesting: 'FLOW_DOCS_REQUESTING',
    received: 'FLOW_DOCS_RECEIVED',
    request_failed: 'FLOW_DOCS_REQUEST_FAILED',
    added: 'FLOW_DOC_ADDED',
    updated: 'FLOW_DOC_UPDATED',
    deleted: 'FLOW_DOC_DELETED',
    invalidated: 'FLOW_DOCS_INVALIDATED'
}

export const DBDATAS_ACTIONS = {
    requesting: 'DBDATAS_REQUESTING',
    received: 'DBDATAS_RECEIVED',
    request_failed: 'DBDATAS_REQUEST_FAILED',
    added: 'DBDATA_ADDED',
    updated: 'DBDATA_UPDATED',
    deleted: 'DBDATA_DELETED',
    invalidated: 'DBDATAS_INVALIDATED'
}

export const DB_VIEWS_ACTIONS = {
    requesting: 'DB_VIEWS_REQUESTING',
    received: 'DB_VIEWS_RECEIVED',
    request_failed: 'DB_VIEWS_REQUEST_FAILED',
    added: 'DB_VIEW_ADDED',
    added_to_bottom: 'DB_VIEW_ADDED_TO_BOTTOM',
    updated: 'DB_VIEW_UPDATED',
    deleted: 'DB_VIEW_DELETED',
    invalidated: 'DB_VIEWS_INVALIDATED'
}

export const DB_VIEW_DATA_ORDER_ACTIONS = {
    requesting: 'DB_VIEW_DATA_ORDER_REQUESTING',
    received: 'DB_VIEW_DATA_ORDER_RECEIVED',
    request_failed: 'DB_VIEW_DATA_ORDER_REQUEST_FAILED',
    added: 'DB_VIEW_DATA_ORDER_ADDED',
    updated: 'DB_VIEW_DATA_ORDER_UPDATED',
    deleted: 'DB_VIEW_DATA_ORDER_DELETED',
    invalidated: 'DB_VIEW_DATA_ORDER_INVALIDATED'
}

export const DOC_HISTORIES_ACTIONS = {
    requesting: 'DOC_HISTORIES_REQUESTING',
    received: 'DOC_HISTORIES_RECEIVED',
    request_failed: 'DOC_HISTORIES_REQUEST_FAILED',
    
    invalidated: 'DOC_HISTORIES_INVALIDATED'
}

export const PRIVATE_DOCS_ACTIONS = {
    requesting: 'PRIVATE_DOCS_REQUESTING',
    received: 'PRIVATE_DOCS_RECEIVED',
    request_failed: 'PRIVATE_DOCS_REQUEST_FAILED',
    added: 'PRIVATE_DOC_ADDED',
    updated: 'PRIVATE_DOC_UPDATED',
    deleted: 'PRIVATE_DOC_DELETED',
    invalidated: 'PRIVATE_DOCS_INVALIDATED'
}

export const SHARED_DOCS_ACTIONS = {
    requesting: 'SHARED_DOCS_REQUESTING',
    received: 'SHARED_DOCS_RECEIVED',
    request_failed: 'SHARED_DOCS_REQUEST_FAILED',
    added: 'SHARED_DOC_ADDED',
    updated: 'SHARED_DOC_UPDATED',
    deleted: 'SHARED_DOC_DELETED',
    invalidated: 'SHARED_DOCS_INVALIDATED'
}

export const WORKSPACE_DOCS_ACTIONS = {
    requesting: 'WORKSPACE_DOCS_REQUESTING',
    received: 'WORKSPACE_DOCS_RECEIVED',
    request_failed: 'WORKSPACE_DOCS_REQUEST_FAILED',
    added: 'WORKSPACE_DOC_ADDED',
    updated: 'WORKSPACE_DOC_UPDATED',
    deleted: 'WORKSPACE_DOC_DELETED',
    invalidated: 'WORKSPACE_DOCS_INVALIDATED'
}

export const DELETED_DOCS_ACTIONS = {
    requesting: 'DELETED_DOCS_REQUESTING',
    received: 'DELETED_DOCS_RECEIVED',
    request_failed: 'DELETED_DOCS_REQUEST_FAILED',
    added: 'DELETED_DOC_ADDED',
    updated: 'DELETED_DOC_UPDATED',
    deleted: 'DELETED_DOC_DELETED',
    invalidated: 'DELETED_DOCS_INVALIDATED'
}

export const SUB_DOCS_ACTIONS = {
    requesting: 'SUB_DOCS_REQUESTING',
    received: 'SUB_DOCS_RECEIVED',
    request_failed: 'SUB_DOCS_REQUEST_FAILED',
    added: 'SUB_DOC_ADDED',
    updated: 'SUB_DOC_UPDATED',
    deleted: 'SUB_DOC_DELETED',
    invalidated: 'SUB_DOCS_INVALIDATED'
}

export const COMMENTS_ACTIONS = {
    requesting: 'COMMENTS_REQUESTING',
    received: 'COMMENTS_RECEIVED',
    request_failed: 'COMMENTS_REQUEST_FAILED',
    added: 'COMMENT_ADDED',
    updated: 'COMMENT_UPDATED',
    deleted: 'COMMENT_DELETED',
    invalidated: 'COMMENTS_INVALIDATED'
}

export const SLIDES_ACTIONS = {
    requesting: 'SLIDES_REQUESTING',
    received: 'SLIDES_RECEIVED',
    request_failed: 'SLIDES_REQUEST_FAILED',
    added: 'SLIDE_ADDED',
    updated: 'SLIDE_UPDATED',
    deleted: 'SLIDE_DELETED',
    invalidated: 'SLIDES_INVALIDATED'
}

export const DELETED_SLIDES_ACTIONS = {
    requesting: 'DELETED_SLIDES_REQUESTING',
    received: 'DELETED_SLIDES_RECEIVED',
    request_failed: 'DELETED_SLIDES_REQUEST_FAILED',
    added: 'DELETED_SLIDE_ADDED',
    updated: 'DELETED_SLIDE_UPDATED',
    deleted: 'DELETED_SLIDE_DELETED',
    invalidated: 'DELETED_SLIDES_INVALIDATED'
}

export const READ_RILS_ACTIONS = {
    requesting: 'READ_RILS_REQUESTING',
    received: 'READ_RILS_RECEIVED',
    request_failed: 'READ_RILS_REQUEST_FAILED',
    added: 'READ_RIL_ADDED',
    updated: 'READ_RIL_UPDATED',
    deleted: 'READ_RIL_DELETED',
    invalidated: 'READ_RILS_INVALIDATED'
}

export const DELETED_RILS_ACTIONS = {
    requesting: 'DELETED_RILS_REQUESTING',
    received: 'DELETED_RILS_RECEIVED',
    request_failed: 'DELETED_RILS_REQUEST_FAILED',
    added: 'DELETED_RIL_ADDED',
    updated: 'DELETED_RIL_UPDATED',
    deleted: 'DELETED_RIL_DELETED',
    invalidated: 'DELETED_RILS_INVALIDATED'
}

export const SEARCHED_RILS_ACTIONS = {
    requesting: 'SEARCHED_RILS_REQUESTING',
    received: 'SEARCHED_RILS_RECEIVED',
    request_failed: 'SEARCHED_RILS_REQUEST_FAILED',
    added: 'SEARCHED_RIL_ADDED',
    updated: 'SEARCHED_RIL_UPDATED',
    deleted: 'SEARCHED_RIL_DELETED',
    invalidated: 'SEARCHED_RILS_INVALIDATED'
}

export const SEARCHED_TODOS_ACTIONS = {
    requesting: 'SEARCHED_TODOS_REQUESTING',
    received: 'SEARCHED_TODOS_RECEIVED',
    request_failed: 'SEARCHED_TODOS_REQUEST_FAILED',
    added: 'SEARCHED_TODO_ADDED',
    updated: 'SEARCHED_TODO_UPDATED',
    deleted: 'SEARCHED_TODO_DELETED',
    invalidated: 'SEARCHED_TODOS_INVALIDATED'
}

export const HIGHLIGHTS_ACTIONS = {
    requesting: 'HIGHLIGHTS_REQUESTING',
    received: 'HIGHLIGHTS_RECEIVED',
    request_failed: 'HIGHLIGHTS_REQUEST_FAILED',
    added: 'HIGHLIGHT_ADDED',
    updated: 'HIGHLIGHT_UPDATED',
    deleted: 'HIGHLIGHT_DELETED',
    invalidated: 'HIGHLIGHTS_INVALIDATED'
}

export const ARTICLE_HIGHLIGHTS_ACTIONS = {
    requesting: 'ARTICLE_HIGHLIGHTS_REQUESTING',
    received: 'ARTICLE_HIGHLIGHTS_RECEIVED',
    request_failed: 'ARTICLE_HIGHLIGHTS_REQUEST_FAILED',
    added: 'ARTICLE_HIGHLIGHT_ADDED',
    updated: 'ARTICLE_HIGHLIGHT_UPDATED',
    deleted: 'ARTICLE_HIGHLIGHT_DELETED',
    invalidated: 'ARTICLE_HIGHLIGHTS_INVALIDATED'
}

export const TODOS_ACTIONS = {
    requesting: 'TODOS_REQUESTING',
    received: 'TODOS_RECEIVED',
    request_failed: 'TODOS_REQUEST_FAILED',
    added: 'TODO_ADDED',
    updated: 'TODO_UPDATED',
    deleted: 'TODO_DELETED',
    invalidated: 'TODOS_INVALIDATED'
}

export const DOINGS_ACTIONS = {
    requesting: 'DOINGS_REQUESTING',
    received: 'DOINGS_RECEIVED',
    request_failed: 'DOINGS_REQUEST_FAILED',
    added: 'DOING_ADDED',
    updated: 'DOING_UPDATED',
    deleted: 'DOING_DELETED',
    invalidated: 'DOINGS_INVALIDATED'
}

export const DONES_ACTIONS = {
    requesting: 'DONES_REQUESTING',
    received: 'DONES_RECEIVED',
    request_failed: 'DONES_REQUEST_FAILED',
    added: 'DONE_ADDED',
    updated: 'DONE_UPDATED',
    deleted: 'DONE_DELETED',
    invalidated: 'DONES_INVALIDATED'
}

export const DELETED_TODOS_ACTIONS = {
    requesting: 'DELETED_TODOS_REQUESTING',
    received: 'DELETED_TODOS_RECEIVED',
    request_failed: 'DELETED_TODOS_REQUEST_FAILED',
    added: 'DELETED_TODO_ADDED',
    updated: 'DELETED_TODO_UPDATED',
    deleted: 'DELETED_TODO_DELETED',
    invalidated: 'DELETED_TODOS_INVALIDATED'
}

export const TAGS_ACTIONS = {
    requesting: 'TAGS_REQUESTING',
    received: 'TAGS_RECEIVED',
    request_failed: 'TAGS_REQUEST_FAILED',
    added: 'TAG_ADDED',
    added_to_bottom: 'TAG_ADDED_TO_BOTTOM',
    updated: 'TAG_UPDATED',
    deleted: 'TAG_DELETED',
    invalidated: 'TAGS_INVALIDATED'
}

export const MESSAGES_ACTIONS = {
    requesting: 'MESSAGES_REQUESTING',
    received: 'MESSAGES_RECEIVED',
    request_failed: 'MESSAGES_REQUEST_FAILED',
    added: 'MESSAGE_ADDED',
    updated: 'MESSAGE_UPDATED',
    deleted: 'MESSAGE_DELETED',
    invalidated: 'MESSAGES_INVALIDATED',
    reset_new_items_counter: 'MESSAGES_NEW_COUNTER_RESET',
}

export const AIMESSAGES_ACTIONS = {
    requesting: 'AIMESSAGES_REQUESTING',
    received: 'AIMESSAGES_RECEIVED',
    request_failed: 'AIMESSAGES_REQUEST_FAILED',
    added: 'AIMESSAGE_ADDED',
    updated: 'AIMESSAGE_UPDATED',
    deleted: 'AIMESSAGE_DELETED',
    invalidated: 'AIMESSAGES_INVALIDATED',
    reset_new_items_counter: 'AIMESSAGES_NEW_COUNTER_RESET',
}

export const PROMPTS_ACTIONS = {
    requesting: 'PROMPTS_REQUESTING',
    received: 'PROMPTS_RECEIVED',
    request_failed: 'PROMPTS_REQUEST_FAILED',
    added: 'PROMPT_ADDED',
    updated: 'PROMPT_UPDATED',
    deleted: 'PROMPT_DELETED',
    invalidated: 'PROMPTS_INVALIDATED',
}

export const PINNED_PROMPTS_ACTIONS = {
    requesting: 'PINNED_PROMPTS_REQUESTING',
    received: 'PINNED_PROMPTS_RECEIVED',
    request_failed: 'PINNED_PROMPTS_REQUEST_FAILED',
    added: 'PINNED_PROMPT_ADDED',
    updated: 'PINNED_PROMPT_UPDATED',
    deleted: 'PINNED_PROMPT_DELETED',
    invalidated: 'PINNED_PROMPTS_INVALIDATED',
}

export const PUBLIC_PROMPTS_ACTIONS = {
    requesting: 'PUBLIC_PROMPTS_REQUESTING',
    received: 'PUBLIC_PROMPTS_RECEIVED',
    request_failed: 'PUBLIC_PROMPTS_REQUEST_FAILED',
    added: 'PUBLIC_PROMPT_ADDED',
    updated: 'PUBLIC_PROMPT_UPDATED',
    deleted: 'PUBLIC_PROMPT_DELETED',
    invalidated: 'PUBLIC_PROMPTS_INVALIDATED',
}

export const WORKSPACE_PROMPTS_ACTIONS = {
    requesting: 'WORKSPACE_PROMPTS_REQUESTING',
    received: 'WORKSPACE_PROMPTS_RECEIVED',
    request_failed: 'WORKSPACE_PROMPTS_REQUEST_FAILED',
    added: 'WORKSPACE_PROMPT_ADDED',
    updated: 'WORKSPACE_PROMPT_UPDATED',
    deleted: 'WORKSPACE_PROMPT_DELETED',
    invalidated: 'WORKSPACE_PROMPTS_INVALIDATED',
}

export const VALIDATE_PROMPTS_ACTIONS = {
    requesting: 'VALIDATE_PROMPTS_REQUESTING',
    received: 'VALIDATE_PROMPTS_RECEIVED',
    request_failed: 'VALIDATE_PROMPTS_REQUEST_FAILED',
    added: 'VALIDATE_PROMPT_ADDED',
    updated: 'VALIDATE_PROMPT_UPDATED',
    deleted: 'VALIDATE_PROMPT_DELETED',
    invalidated: 'VALIDATE_PROMPTS_INVALIDATED',
}

export const SUB_TICKETS_ACTIONS = {
    requesting: 'SUB_TICKETS_REQUESTING',
    received: 'SUB_TICKETS_RECEIVED',
    request_failed: 'SUB_TICKETS_REQUEST_FAILED',
    added: 'SUB_TICKET_ADDED',
    updated: 'SUB_TICKET_UPDATED',
    deleted: 'SUB_TICKET_DELETED',
    invalidated: 'SUB_TICKETS_INVALIDATED'
}

export const KEY_RESULTS_ACTIONS = {
    requesting: 'KEY_RESULTS_REQUESTING',
    received: 'KEY_RESULTS_RECEIVED',
    request_failed: 'KEY_RESULTS_REQUEST_FAILED',
    added: 'KEY_RESULT_ADDED',
    updated: 'KEY_RESULT_UPDATED',
    deleted: 'KEY_RESULT_DELETED',
    invalidated: 'KEY_RESULTS_INVALIDATED'
}

export const MY_KRS_ACTIONS = {
    requesting: 'MY_OBJ_KRS_REQUESTING',
    received: 'MY_OBJ_KRS_RECEIVED',
    request_failed: 'MY_OBJ_KRS_REQUEST_FAILED',
    invalidated: 'MY_OBJ_KRS_INVALIDATED'
}

export const GROUP_KRS_ACTIONS = {
    requesting: 'GROUP_OBJ_KRS_REQUESTING',
    received: 'GROUP_OBJ_KRS_RECEIVED',
    request_failed: 'GROUP_OBJ_KRS_REQUEST_FAILED',
    invalidated: 'GROUP_OBJ_KRS_INVALIDATED'
}

export const COMPANY_KRS_ACTIONS = {
    requesting: 'COMPANY_OBJ_KRS_REQUESTING',
    received: 'COMPANY_OBJ_KRS_RECEIVED',
    request_failed: 'COMPANY_OBJ_KRS_REQUEST_FAILED',
    invalidated: 'COMPANY_OBJ_KRS_INVALIDATED'
}

export const PEOPLE_KRS_ACTIONS = {
    requesting: 'PEOPLE_OBJ_KRS_REQUESTING',
    received: 'PEOPLE_OBJ_KRS_RECEIVED',
    request_failed: 'PEOPLE_OBJ_KRS_REQUEST_FAILED',
    invalidated: 'PEOPLE_OBJ_KRS_INVALIDATED'
}

export const GROUP_TICKETS_ACTIONS = {
    requesting: 'GROUP_TICKETS_REQUESTING',
    received: 'GROUP_TICKETS_RECEIVED',
    request_failed: 'GROUP_TICKETS_REQUEST_FAILED',
    added: 'GROUP_TICKET_ADDED',
    updated: 'GROUP_TICKET_UPDATED',
    deleted: 'GROUP_TICKET_DELETED',
    invalidated: 'GROUP_TICKETS_INVALIDATED'
}

export const GROUP_TODOS_ACTIONS = {
    requesting: 'GROUP_TODOS_REQUESTING',
    received: 'GROUP_TODOS_RECEIVED',
    request_failed: 'GROUP_TODOS_REQUEST_FAILED',
    added: 'GROUP_TODO_ADDED',
    updated: 'GROUP_TODO_UPDATED',
    deleted: 'GROUP_TODO_DELETED',
    invalidated: 'GROUP_TODOS_INVALIDATED'
}

export const GROUP_DOINGS_ACTIONS = {
    requesting: 'GROUP_DOINGS_REQUESTING',
    received: 'GROUP_DOINGS_RECEIVED',
    request_failed: 'GROUP_DOINGS_REQUEST_FAILED',
    added: 'GROUP_DOING_ADDED',
    updated: 'GROUP_DOING_UPDATED',
    deleted: 'GROUP_DOING_DELETED',
    invalidated: 'GROUP_DOINGS_INVALIDATED'
}

export const GROUP_DONES_ACTIONS = {
    requesting: 'GROUP_DONES_REQUESTING',
    received: 'GROUP_DONES_RECEIVED',
    request_failed: 'GROUP_DONES_REQUEST_FAILED',
    added: 'GROUP_DONE_ADDED',
    updated: 'GROUP_DONE_UPDATED',
    deleted: 'GROUP_DONE_DELETED',
    invalidated: 'GROUP_DONES_INVALIDATED'
}

export const GROUP_MEMBERS_ACTIONS = {
    requesting: 'GROUP_MEMBERS_REQUESTING',
    received: 'GROUP_MEMBERS_RECEIVED',
    request_failed: 'GROUP_MEMBERS_REQUEST_FAILED',
    added: 'GROUP_MEMBER_ADDED',
    added_to_bottom: 'GROUP_MEMBER_ADDED_TO_BOTTOM',
    updated: 'GROUP_MEMBER_UPDATED',
    deleted: 'GROUP_MEMBER_DELETED',
    invalidated: 'GROUP_MEMBERS_INVALIDATED'
}

export const TICKET_ACTIONS = {
    received: 'TICKET_RECEIVED',
    updated: 'TICKET_UPDATED',
    invalidated: 'TICKET_INVALIDATED'
}

export const DB_VIEW_ACTIONS = {
    received: 'DB_VIEW_RECEIVED',
    updated: 'DB_VIEW_UPDATED',
    invalidated: 'DB_VIEW_INVALIDATED'
}

export const DOC_ACTIONS = {
    received: 'DOC_RECEIVED',
    updated: 'DOC_UPDATED',
    invalidated: 'DOC_INVALIDATED',
    grantedPermission: 'DOC_GRANTED_PERMISSION'
}

export const DOC_HTML_ACTIONS = {
    received: 'DOC_HTML_RECEIVED',
    updated: 'DOC_HTML_UPDATED',
    invalidated: 'DOC_HTML_INVALIDATED'
}

export const VIEW_SORT_ACTIONS = {
    received: 'VIEW_DATA_HANDLER_RECEIVED',
    updated: 'VIEW_DATA_HANDLER_UPDATED',
    invalidated: 'VIEW_DATA_HANDLER_INVALIDATED'
}

export const VIEW_FILTER_ACTIONS = {
    received: 'VIEW_FILTER_RECEIVED',
    updated: 'VIEW_FILTER_UPDATED',
    invalidated: 'VIEW_FILTER_INVALIDATED'
}

export const HISTORY_DOC_ACTIONS = {
    received: 'HISTORY_DOC_RECEIVED',
    updated: 'HISTORY_DOC_UPDATED',
    invalidated: 'HISTORY_DOC_INVALIDATED',
}

export const ARTICAL_ACTIONS = {
    received: 'ARTICAL_RECEIVED',
    updated: 'ARTICAL_UPDATED',
    invalidated: 'ARTICAL_INVALIDATED'
}

export const PROMPT_ACTIONS = {
    received: 'PROMPT_RECEIVED',
    updated: 'PROMPT_UPDATED',
    invalidated: 'PROMPT_INVALIDATED'
}

export const TTS_STATUS_ACTIONS = {
    received: 'TTS_STATUS_RECEIVED',
    updated: 'TTS_STATUS_UPDATED',
    invalidated: 'TTS_STATUS_INVALIDATED'
}

export const OKR_ACTIONS = {
    received: 'OKR_RECEIVED',
    updated: 'OKR_UPDATED',
    invalidated: 'OKR_INVALIDATED'
}

export const TICKET_DRAFT_ACTIONS = {
    updated: 'TICKET_DRAFT_UPDATED',
    invalidated: 'TICKET_DRAFT_INVALIDATED'
}

export const SLIDES_DRAFT_ACTIONS = {
    updated: 'SLIDES_DRAFT_UPDATED',
    invalidated: 'SLIDES_DRAFT_INVALIDATED'
}

export const FEEDBACKS_ACTIONS = {
    requesting: 'FEEDBACKS_REQUESTING',
    received: 'FEEDBACKS_RECEIVED',
    request_failed: 'FEEDBACKS_REQUEST_FAILED',
    added: 'FEEDBACK_ADDED',
    updated: 'FEEDBACK_UPDATED',
    deleted: 'FEEDBACK_DELETED',
    invalidated: 'FEEDBACKS_INVALIDATED'
}

export const PAGE_HISTORY_ACTIONS = {
    added: 'ADD_PAGE_TO_HISTORY'
}

export const LOGIN_ACTIONS = {
    in: 'LOGIN_IN',
    out: 'LOGIN_OUT',
    workspace: 'WORK_SPACE'
}

export const LIST_KEYS = {
    rils: '',
    highlights: '',
    todos: '',
    posts: '',
    threads: '',
    groups: '',
    orgs: '',
    okrs: '',
    followers: '',
    tags: '',
    slides: '',
    doc: '',
    feedbacks: ''
}

export const SETTINGS = {
    RESET_CACHE: 'RESET_CACHE'
}

export const OPERATION_FAILED = 'OPERATION_FAILED';
export const OPERATION_IN_PROGRESS = 'OPERATION_IN_PROGRESS';
export const OPERATION_SUCCESS = 'OPERATION_SUCCESS';
export const OPERATION_RESULT_HANDLED = 'OPERATION_RESULT_HANDLED';


export const SIDE_BAR_SHOW = 'SIDE_BAR_SHOW';
export const DOC_PATH_ADD = 'DOC_PATH_ADD';
export const DOC_PATH_CLEAR = 'DOC_PATH_CLEAR';
export const DOC_REFS = 'DOC_REFS';
export const APP_LIST = 'APP_LIST';
export const SHOW_APP_LIST = 'SHOW_APP_LIST';
export const EXPORT_DOC_DIALOG = 'EXPORT_DOC_DIALOG';
export const IMPORT_DOC_DIALOG = 'IMPORT_DOC_DIALOG';
export const INVITE_DOC_DIALOG = 'INVITE_DOC_DIALOG';
export const INVITE_ORG_DIALOG = 'INVITE_ORG_DIALOG';
export const ADD_GROUP_DIALOG = 'ADD_GROUP_DIALOG';
export const ADD_USER_TO_GROUP_DIALOG = 'ADD_USER_TO_GROUP_DIALOG';
export const RIL_TITLE_DIALOG = 'RIL_TITLE_DIALOG';
export const NOTE_DIALOG = 'NOTE_DIALOG';
export const RIL_DIALOG = 'RIL_DIALOG';
export const CONFIRM_DIALOG = 'CONFIRM_DIALOG';
export const ALERT_DIALOG = 'ALERT_DIALOG';
export const JOIN_WORKSPACE_DIALOG = 'JOIN_WORKSPACE_DIALOG';
export const SETTINGS_DIALOG = 'SETTINGS_DIALOG';
export const DOC_HISTORY_DIALOG = 'DOC_HISTORY_DIALOG';
export const DATA_EDITOR_DIALOG = 'DATA_EDITOR_DIALOG';
export const DB_EDITOR_DIALOG = 'DB_EDITOR_DIALOG';
export const EDITOR_DIALOG = 'EDITOR_DIALOG';
export const EMOJI_PICKER_DIALOG = 'EMOJI_PICKER_DIALOG';
export const FEEDBACKS_DIALOG = 'FEEDBACKS_DIALOG';
export const INVITE_FRIENDS_DIALOG = 'INVITE_FRIENDS_DIALOG';
export const APP_UPGRADE_DIALOG = 'APP_UPGRADE_DIALOG';
export const IMAGE_UPLOAD_DIALOG = 'IMAGE_UPLOAD_DIALOG';
export const POLL_GENERATOR_DIALOG = 'POLL_GENERATER_DIALOG';
export const CHART_GENERATOR_DIALOG = 'CHART_GENERATER_DIALOG';
export const LINK_INPUT_DIALOG = 'LINK_INPUT_DIALOG';
export const TABLE_BUILDER_DIALOG = 'TABLE_BUILDER_DIALOG';
export const PROMPT_DIALOG = 'PROMPT_DIALOG';
export const PROMPTS_DIALOG = 'PROMPTS_DIALOG';
export const STRIPE_CHECKOUT_DIALOG = 'STRIPE_CHECKOUT_DIALOG';
export const BILL_PERIOD_DIALOG = 'BILL_PERIOD_DIALOG';
export const REFRESH_RILS = 'REFRESH_RILS';
export const RILS_FOLDER = 'RILS_FOLDER';
export const REFRESH_SLIDES = 'REFRESH_SLIDES';
export const SLIDES_FOLDER = 'SLIDES_FOLDER';
export const LNG_SETTING = 'LNG_SETTING';
export const LNG_LIST = 'LNG_LIST';
export const TO_HTML = 'TO_HTML';
export const TEXT_SELECTION = 'TEXT_SELECTION';
export const TITLE_SELECTION = 'TITLE_SELECTION';
export const SLIDE_EDITOR_MESSAGE = 'SLIDE_EDITOR_MESSAGE';
export const DB_CAPABILITY_ENABLED = 'DB_CAPABILITY_ENABLED';
export const INVITED_CODE = 'INVITED_CODE';
export const INVITED_RECORD = 'INVITED_RECORD';
export const UNSAVED_FLOW = 'UNSAVED_FLOW';
export const FLOW_MODAL = 'FLOW_MODAL';
export const FLOW_INPUT_MODAL = 'FLOW_INPUT_MODAL';
export const FLOW_EXPORT_IMAGE_WITHOUT_QRCODE = 'FLOW_EXPORT_IMAGE_WITHOUT_QRCODE';
export const AI_API_MODEL = 'AI_API_MODEL';
export const AI_USE_SEARCH = 'AI_USE_SEARCH';
export const LLM_API_KEY_MODAL = 'LLM_API_KEY_MODAL';
export const LLM_API_KEYS = 'LLM_API_KEYS';
export const FLOW_SETTINGS = 'FLOW_SETTINGS';
export const PAGE_CHOOSER_DIALOG = 'PAGE_CHOOSER_DIALOG';

export const TAKE_TICKET_MODAL = 'TAKE_TICKET_MODAL';
export const LIST_JUMPED = 'LIST_JUMPED';
export const TICKET_DETAIL = {
    comments_header_height: 'COMMENTS_HEADER_HEIGHT',
    comment_form_height: 'COMMENT_FORM_HEIGHT'
};
export const TICKET_IN_VIEW = 'TICKET_IN_VIEW';
export const SCREEN_SIZE = 'SCREEN_SIZE';
export const LIST_ITEM_SELECTED = 'LIST_ITEM_SELECTED';
export const OKR_LIST_FILTERS = 'OKR_LIST_FILTERS';
export const SHOWN_CLIPBOARD_URL = 'SHOWN_CLIPBOARD_URL';
export const NET_INFO = 'NET_INFO';
export const AUDIO_FLOAT_CONTROLLER_OPEN = 'AUDIO_FLOAT_CONTROLLER_OPEN';
export const AUDIO_FLOAT_CONTROLLER_CLOSE = 'AUDIO_FLOAT_CONTROLLER_CLOSE';
export const AUDIO_FLOAT_CONTROLLER_UI_VISIBLE = 'AUDIO_FLOAT_CONTROLLER_UI_VISIBLE';
export const READING_ARTICLE = 'READING_ARTICLE';
export const READING_LIST= 'READING_LIST';
export const AUDIO_READ_SPEED = 'AUDIO_READ_SPEED';
export const AUDIO_READ_PITCH = 'AUDIO_READ_PITCH';
export const AUDIO_READ_VOICE = 'AUDIO_READ_VOICE';
export const PROMOTE_VIP = 'PROMOTE_VIP';
export const REFRESH_EDITOR_CONTENT = 'REFRESH_EDITOR_CONTENT';
export const REFRESH_DOC = 'REFRESH_DOC';
export const CURRENT_EDITOR = 'CURRENT_EDITOR';
export const APP_CONFIG = 'APP_CONFIG';
export const AI_ASSISTANT_DIALOG = 'AI_ASSISTANT_DIALOG';
export const SERVING_PRODUCTS = 'SERVING_PRODUCTS';
export const SERVING_PRODUCT = 'SERVING_PRODUCT';
export const AVAILABLE_PRODUCTS = 'AVAILABLE_PRODUCTS';
export const AI_COINS_PRODUCTS = 'AI_COINS_PRODUCTS';
export const AI_SETTINGS = 'AI_SETTINGS';
export const AI_TOKEN_BALANCE_TRANS = 'AI_TOKEN_BALANCE_TRANS';
export const ai_remain_quotas = 'ai_remain_quotas';
export const USER_GUIDES = 'USER_GUIDES';
export const ADD_FLOW_NODE = 'ADD_FLOW_NODE';

export const getCommonListAction = (action_group ,action_type) => {
    let idx = Object.values(action_group).indexOf(action_type);
    return ITEMS_ACTIONS[Object.keys(action_group)[idx]];
}