import * as storage from './storage';
import { isDEV } from '../constants/constants';
import { getMainDomain, isCNDomain } from './constants';

let server_host = null;
console.log('is cn domain...........', isCNDomain())
const domain = `https://service.${getMainDomain()}/`;
export async function set_server_host(host) {
    server_host = host;
    await storage.storeData('server_host', server_host);

}
export async function get_server_host() {
    if (!isDEV) {
        // return 'https://api.xslides.net/';
        // return 'https://xservice.e-du.top/';
        return domain;
    }

    // return 'https://192.168.178.124:50048/';
    // return 'https://service.funblocks.net/';

    if (server_host) {
        return server_host;
    }

    server_host = await storage.getData('server_host');

    if (!server_host) {
        server_host = 'http://localhost:50058/';
    }

    return server_host;
}
export const server_hosts = () => [
    { label: 'http://www.e-du.top/', value: 'http://www.e-du.top/' },
    { label: '127.0.0.1', value: 'http://localhost:50058/' },
    { label: 'my mac server', value: 'http://192.168.17.123:50058/' },
    // { label: 'direct server', value: 'https://api.xslides.net/' },
    // { label: 'direct server', value: 'https://xservice.e-du.top/' },
    { label: 'direct server', value: domain },
    { label: 'no https', value: 'http://xservice.e-du.top/' }
];

export async function clearAccessCookie() {
    document.cookie.split(";").forEach(function (c) {
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });

    localStorage.clear();
    sessionStorage.clear();

    await storage.storeData('cookie', '');
}

/** 
 * NOTE: If what to attach files, set params.enctype='multipart', and set params.files=[...]
 **/
export async function funblocks_api_fetch(api, req_method, params, timeoutT = 240000) {
    let url = await get_server_host() + api;

    return await api_fetch(url, req_method, {}, 'cors', params, timeoutT, true);
};

/** 
 * NOTE: If what to attach files, set params.enctype='multipart', and set params.files=[...]
 **/
export async function api_fetch(url, req_method, headers, mode, params, timeoutT = 240000, includeCredentials = false) {
    if (req_method == 'GET' && params && typeof params == 'object') {
        let keys = Object.keys(params);
        let paramStr = '';
        keys.forEach((item, index) => {
            if (params[item] === null || params[item] === undefined) {
                return;
            }
            if (paramStr) {
                paramStr += '&';
            }
            paramStr += item + '=' + params[item];
        })

        if (paramStr) {
            url = url + '?' + paramStr;
        }
    }

    let fetchParams = {
        method: req_method,
        headers,
    }

    if (mode) {
        fetchParams.mode = mode;
    }

    if (includeCredentials) {
        fetchParams.credentials = 'include';
    }

    if ((req_method == 'POST' || req_method == 'PUT' || req_method == 'DELETE') && params && typeof params == 'object') {
        if (params.enctype != 'multipart') {
            params = JSON.stringify(params);
            fetchParams.body = params;
            fetchParams.headers["Content-Type"] = "application/json";
        } else {
            let data = new FormData();
            Object.keys(params).forEach(key => {
                if (!params[key]) {
                    return;
                }

                if (Array.isArray(params[key])) {
                    params[key].forEach(ele => {
                        if (key != 'files' && typeof ele == 'object') {
                            data.append(key, JSON.stringify(ele));
                        } else {
                            data.append(key, ele);
                        }
                    })
                } else if (typeof params[key] == 'object') {
                    data.append(key, JSON.stringify(params[key]));
                } else {
                    data.append(key, params[key]);
                }
            });
            fetchParams.body = data;
        }
    }

    if (process.env.NODE_ENV !== 'production') {
        console.log('url.....................', url, fetchParams)
    }

    const controller = new AbortController();
    const signal = controller.signal;

    const fetchPromise = fetch(url, { ...fetchParams, signal });

    const timeoutPromise = new Promise((_, reject) => 
        setTimeout(() => {
            controller.abort();
            reject(new Error('Request timed out'));
        }, timeoutT)
    );

    return Promise.race([fetchPromise, timeoutPromise])
        .then(async response => {
            let newCookie = response.headers.get("set-cookie");
            if (newCookie) {
                newCookie = newCookie.match(/connect.sid=(.*?);/);
                if (newCookie && newCookie.length > 0) {
                    newCookie = newCookie[0];
                    await storage.storeData('cookie', newCookie);
                }
            }
            return response;
        });
};

export const apiCaller = async ({ apiAddr, req_method, params, successCallback, unloginCallback, failureCallback }) => {
    let result = null;
    let errMessage = 'operation failed';
    try {
        let response = await funblocks_api_fetch(apiAddr, req_method, params);
        if (response.status === 403) {
            return unloginCallback();
        } else if (response.status === 400) {
            throw 'Wrong params';
        }

        result = await response.json();
    } catch (error) {
        console.log('error msg', error)
        errMessage = error;
    }

    if (result && result.success == 1) {
        if (result.data) {
            let item = result.data;
            return successCallback(item);
        }
    }

    if (result && result.success == 0) {
        errMessage = result.message;
    }

    failureCallback(errMessage);
}

export const openAIStreamGenerate = async (endpoint, api_token, params, respondCallback, contentCallback, errCallback, doneCallback) => {
    params.stream = true;

    let headers = { "Content-Type": "application/json" };
    if (endpoint?.includes('.azure.')) {
        headers["api-key"] = api_token;
    } else {
        headers["Authorization"] = 'Bearer ' + api_token;
    }

    return await aiStreamClient(
        `${endpoint || 'https://api.openai.com/v1'}/chat/completions`,
        headers,
        params, respondCallback, errCallback, doneCallback,
        (lines) => {
            return lines
                .map((line) => line.replace(/^data: /, "").trim()) // Remove the "data: " prefix
                .filter((line) => line !== "" && line !== "[DONE]") // Remove empty lines and "[DONE]"
                .map((line) => {
                    try {
                        return JSON.parse(line)

                    } catch (error) {
                        return {
                            choices: [{ delta: { content: '' } }]
                        }
                    }
                }); // Parse the JSON string
        },
        (parsedLine) => {
            const { choices } = parsedLine;
            const { delta } = choices[0];
            const { content } = delta;
            // Update the UI with the new content
            if (content) {
                // resultText.innerText += content;
                contentCallback(content)
            }
        }
    );
}

export const geminiStreamGenerate = async (endpoint, api_token, params, respondCallback, contentCallback, errCallback, doneCallback) => {
    return await aiStreamClient(
        `${endpoint || 'https://generativelanguage.googleapis.com/v1beta'}/${params.model}:streamGenerateContent?alt=sse&key=${api_token}`,
        {
            "Content-Type": "application/json",
        },
        params, respondCallback, errCallback, doneCallback,
        (lines) => {
            return lines
                .map((line) => line.replace(/^data: /, "").trim()) // Remove the "data: " prefix
                .filter((line) => line !== "" && line !== "[DONE]") // Remove empty lines and "[DONE]"
                .map((line) => {
                    try {
                        return JSON.parse(line)

                    } catch (error) {
                        return {
                            candidates: []
                        }
                    }
                }); // Parse the JSON string
        },
        (parsedLine) => {
            // console.log("parsed line.......", parsedLine)
            const { candidates, error, promptFeedback } = parsedLine;
            if (error) {
                errCallback(error.message)
            } else if (promptFeedback?.blockReason) {
                errCallback("Blocked reason: " + promptFeedback.blockReason)
            }

            if (candidates && candidates[0]) {
                const { content, finishReason } = candidates[0];

                if (finishReason == "SAFETY") {
                    errCallback("Blocked reason: SAFETY")
                } else if (content?.parts) {
                    let text = content.parts.map((part, index) => {
                        if (!index && params.model?.includes('think')) {
                            return null;
                        }

                        return part.text.trim();
                    }).filter(text => !!text).join('\n\n');

                    contentCallback(text)
                }
            }
        }
    );
}

const aiStreamClient = async (endpoint, headers, params, respondCallback, errCallback, doneCallback, linesParser, lineProcessor) => {
    console.log('stream generate............', { endpoint, params, headers })

    let options = {
        method: "POST",
        headers,
        body: JSON.stringify(params),
        // signal, // Pass the signal to the fetch request
    };

    // if(endpoint.includes('.moonshot.')) {
    //     options.mode = 'no-cors';
    // }

    try {
        // Fetch the response from the OpenAI API with the signal from AbortController
        const response = await fetch(endpoint, options);

        // Read the response as a stream of data
        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");

        if (reader) {
            respondCallback();
        }

        while (true) {
            const { done, value } = await reader.read();
            if (done) {
                break;
            }
            // Massage and parse the chunk of data
            const chunk = decoder.decode(value);
            // console.log('chunk...........', chunk)
            const lines = chunk.split(/\n\n|\r\r|\r\n/);

            // console.log('lines...........', lines)
            const parsedLines = linesParser(lines);
            // console.log('parsedLines........', parsedLines)

            for (const parsedLine of parsedLines) {
                lineProcessor(parsedLine)
            }
        }
    } catch (error) {
        // Handle fetch request errors
        // if (signal.aborted) {
        //   resultText.innerText = "Request aborted.";
        // } else {
        console.error("Error:", error);
        // resultText.innerText = "Error occurred while generating.";
        errCallback("Error occurred while generating.")
        // }
    } finally {
        // Enable the generate button and disable the stop button
        // generateBtn.disabled = false;
        // stopBtn.disabled = true;
        // controller = null; // Reset the AbortController instance
        doneCallback()

    }
}