import { useIntl } from 'react-intl'

import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import { formatDate } from '@/utils/timeFormater'
import { INVITE_FRIENDS_DIALOG, SETTINGS_DIALOG } from '@/constants/actionTypes'
import { getAICoinBalanceAndTrans, getAIRemainDailyQuotas } from '@/actions/ticketAction'
import ModelSelector from '../flow/ModelSelector'
import { Button } from '@mui/material'

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const General = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const loginUser = useSelector((state) => state.loginIn.user);
  const settingsDialogState = useSelector(state => state.uiState.settingsDialog) || {};

  const ai_tokens_trans = useSelector(
    (state) => state.uiState.ai_token_balance_trans
  )

  const ai_remain_quotas = useSelector(
    (state) => state.uiState.ai_remain_quotas
  )

  useEffect(() => {
    if (!loginUser?._id) {
      return
    }

    dispatch(getAICoinBalanceAndTrans({}, null, 'settings'))
    dispatch(getAIRemainDailyQuotas({}, null, 'settings'));
  }, [loginUser])

  return (
    <div
      className='fill-available'
      style={{
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: 10,
        paddingBottom: 40,
      }}
    >
      <div style={styles.item}>
        <div style={styles.title}>
          {intl.formatMessage({ id: 'set_default_ai_model' }) + ':'}
        </div>

        <ModelSelector />
      </div>

      {
        ai_remain_quotas && <>
          {
            ai_remain_quotas.servingProduct?.productId &&
            <div style={{ ...styles.item, alignItems: 'center', paddingTop: 24, paddingBottom: 10 }}>
              <div style={{ ...styles.section_title, paddingTop: 0, paddingBottom: 0 }}>
                Current Plan:
              </div>
              <div style={{ fontSize: 22 }}>
                AI {capitalizeFirstLetter(ai_remain_quotas.servingProduct.productId)}
              </div>
              <Button variant="contained" color='success' size="small" style={{ marginLeft: 10 }} onClick={() => {
                dispatch({
                  type: SETTINGS_DIALOG,
                  value: {
                    ...settingsDialogState,
                    page: 'service_subscribe'
                  }
                });
              }}>Upgrade Plan</Button>
            </div>
          }
          <div style={{ color: 'gray', fontSize: 14 }}>
            AI-related quotas (Remaining/Total):
          </div>
          <div>
            {
              ['t1', 't2', 'whiteboards', 'flowNodes'].map((item, index) => {
                return (
                  <div key={index + ''} style={{ ...styles.item, paddingTop: 10 }}>
                    <div style={styles.title}>
                      {ai_remain_quotas[item]?.level}:
                    </div>
                    <div style={{ fontSize: 16, fontWeight: 500 }}>
                      {ai_remain_quotas[item]?.quota === 'unlimited' && 'Unlimited'}
                      {ai_remain_quotas[item]?.quota !== 'unlimited' && (ai_remain_quotas[item]?.remain === undefined? `${ai_remain_quotas?.[item]?.quota}` : `${ai_remain_quotas[item]?.remain}/${ai_remain_quotas?.[item]?.quota}`)}
                    </div>
                  </div>
                )
              })
            }
          </div>
        </>
      }


      <div style={styles.section_title}>
        {intl.formatMessage({ id: 'ai_token' })}
      </div>
      <div style={styles.section_box}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            fontSize: 16,
            rowGap: 4,
            padding: 6,
          }}
        >
          <p>
            {intl.formatMessage({ id: 'your_ai_token_balance' })}{' '}
            {ai_tokens_trans?.balance?.coins || 0}&nbsp;
            <span style={{ fontSize: 14, color: 'GrayText' }}>
              {intl.formatMessage({ id: 'ai_token_desc' })}
            </span>
          </p>
          {!!ai_tokens_trans?.trans?.length && (
            <div>
              <p>
                {intl.formatMessage({ id: 'ai_tokens_income' })}&nbsp;
                <span style={{ fontSize: 14, color: 'GrayText' }}>
                  {intl.formatMessage(
                    { id: 'show_latest_records' },
                    { count: 10 }
                  )}
                </span>
              </p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  padding: 20,
                  backgroundColor: '#f3f3f3',
                  borderRadius: 10,
                  marginTop: 10,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    fontWeight: 500,
                  }}
                >
                  <div style={{ flex: 1 }}>
                    {intl.formatMessage({ id: 'income_item' })}
                  </div>
                  <div style={{ flex: 1 }}>
                    {intl.formatMessage({ id: 'count' })}
                  </div>
                  <div style={{ flex: 1 }}>
                    {intl.formatMessage({ id: 'date' })}
                  </div>
                </div>

                {ai_tokens_trans.trans.map((item, index) => {
                  return (
                    <div key={index + ''}>
                      <div
                        style={{
                          height: 1,
                          backgroundColor: '#ccc',
                          marginTop: 8,
                        }}
                      />
                      <div
                        key={index + ''}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          fontSize: 14,
                          paddingTop: 8,
                          color: '#444',
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          {intl.formatMessage({ id: item.activity })}
                        </div>
                        <div style={{ flex: 1 }}>{item.coins}</div>
                        <div style={{ flex: 1 }}>
                          {formatDate(item.createdAt)}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
          <div style={{ paddingTop: 8 }}>
            <span>{intl.formatMessage({ id: 'wanna_more_ai_tokens' })}</span>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                cursor: 'pointer',
                color: 'dodgerblue',
                marginTop: 3,
              }}
              onClick={() => {
                // window.open(`${window.location.origin}/#/invitation-event`)
                dispatch({ type: SETTINGS_DIALOG, value: { visible: false } });
                dispatch({ type: INVITE_FRIENDS_DIALOG, value: { visible: true } });
              }}
            >
              <span>🎁&nbsp;</span>
              {intl.formatMessage({ id: 'invite_friends' })}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                cursor: 'pointer',
                color: 'dodgerblue',
                marginTop: 3,
              }}
              onClick={() => {
                if (!window.location.href.includes('/aiflow')) {
                  window.open(`${window.location.origin}/#/aiflow`, '_blank');
                }

                dispatch({ type: SETTINGS_DIALOG, value: { visible: false } });
              }}
            >
              <span>👩‍💻&nbsp;</span>
              {intl.formatMessage({ id: 'share_aigc' })}
            </div>
            <div style={{
              paddingLeft: 20,
              fontSize: 14,
              color: 'GrayText'
            }}>{intl.formatMessage({ id: 'share_aigc_tips' })}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = {
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
    paddingTop: '10px',
    columnGap: '10px',
  },

  title: {
    fontWeight: 500,
    fontSize: 16,
    whiteSpace: 'nowrap',
  },

  section_title: {
    fontWeight: '500',
    fontSize: 24,
    whiteSpace: 'nowrap',
    paddingTop: 34,
    paddingBottom: 12,
  },

  section_box: {
    padding: 14,
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
    border: '1px solid #e3e3e3',
    borderRadius: 6,
  },

  input: {
    padding: '3px 12px',
    border: '1px solid #ccc',
    borderRadius: 15,
    outline: 'none',
    minWidth: '400px',
    fontSize: 14,
  },
  selector: {
    border: '1px solid #ccc',
    paddingLeft: 12,
    paddingRight: 6,
    borderRadius: 15,
    minWidth: 80,
  },
}

export default General
