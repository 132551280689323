import { useIntl } from "react-intl"
import PrivilegeItem from "./PrivilegeItem"
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MEDIA_QUERY } from "../../utils/constants";

const PrivilegesAI = ({ services = ['aiplus'], privileges_primitive, getQuotaText, capitalizeFirstLetter, sku }) => {
    const intl = useIntl();
    const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

    if (!privileges_primitive) return;

    const generateCardSection = privileges_primitive?.generateCard && <>
        <div style={styles.advantage_title}>{intl.formatMessage({ id: 'generate_insights_cards' })}</div>
        <PrivilegeItem text={intl.formatMessage({ id: 'generate_insights_cards_quota' }, { quota: getQuotaText(privileges_primitive?.generateCard, intl) })} />
        <div className="fill-available" style={{ height: 1, marginLeft: 26, marginRight: 26, marginBottom: 4, marginTop: 4, backgroundColor: '#eee' }}></div>
    </>

    const askAISection = privileges_primitive?.askAI && <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <div style={styles.advantage_title}>{intl.formatMessage({ id: 'more_queries_per_day' })}</div>
        {sku.service_level !== 'basic' && <PrivilegeItem text={intl.formatMessage({ id: 'query_quota' }, { quota: getQuotaText({ ...privileges_primitive?.askAI, value: privileges_primitive?.askAI?.t1 }, intl), model_level: 'Advanced AI queries' })} />}
        <PrivilegeItem text={intl.formatMessage({ id: 'query_quota' }, { quota: getQuotaText({ ...privileges_primitive?.askAI, value: privileges_primitive?.askAI?.t2 }, intl), model_level: 'Standard AI queries' })} />
        <div className="fill-available" style={{ height: 1, marginLeft: 26, marginRight: 26, marginBottom: 4, marginTop: 4, backgroundColor: '#eee' }}></div>
    </div>

    const memosSection = (privileges_primitive?.memos || privileges_primitive?.whiteboards) && <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <div style={styles.advantage_title}>{intl.formatMessage({ id: 'more_capacity' })}</div>
        <PrivilegeItem text={intl.formatMessage({ id: 'flow_whiteboards_capacity' }, { quota: getQuotaText(privileges_primitive?.whiteboards, intl) })} />
        <PrivilegeItem text={intl.formatMessage({ id: 'flow_workspaces_capacity' }, { quota: getQuotaText(privileges_primitive?.workspaces, intl) })} />
        <PrivilegeItem text={intl.formatMessage({ id: 'flow_flowNodes_capacity' }, { quota: getQuotaText(privileges_primitive?.flowNodes, intl) })} />
        
        {sku.service_level !== 'basic' && <PrivilegeItem text={intl.formatMessage({ id: 'memos_capacity' }, { quota: getQuotaText(privileges_primitive?.memos, intl) })} desc={intl.formatMessage({ id: 'memos_capacity_desc' })} />}

        <div className="fill-available" style={{ height: 1, marginLeft: 26, marginRight: 26, marginBottom: 4, marginTop: 4, backgroundColor: '#eee' }}></div>
    </div>

    const advancedFeaturesSection = !privileges_primitive?.limited_ai_features && <>
        <div style={styles.advantage_title}>{intl.formatMessage({ id: 'advanced_features' })}</div>
        <div style={{
            display: 'grid',
            gridTemplateColumns: `repeat(${sku.service_level === 'basic' && !isMobile ? 3 : 1}, 1fr)`,
            gap: '8px',
            width: '100%'
        }}>
            <PrivilegeItem text={intl.formatMessage({ id: 'ai_flow' })} />
            <PrivilegeItem text={intl.formatMessage({ id: 'ai_slides' })} />
            <PrivilegeItem text={intl.formatMessage({ id: 'ai_infographics' })} />
            <PrivilegeItem text={intl.formatMessage({ id: 'reading_assistant' })} />
            <PrivilegeItem text={intl.formatMessage({ id: 'writing_assistant' })} />
            <PrivilegeItem text={intl.formatMessage({ id: 'ai_tools' })} />
            <PrivilegeItem text={intl.formatMessage({ id: 'other_llm_providers_enabled' })}
            // desc={intl.formatMessage({ id: 'other_llm_providers_enabled_desc' })}
            />
            <PrivilegeItem text={intl.formatMessage({ id: 'build_ai_apps' })} />
        </div>

        <div className="fill-available" style={{ height: 1, marginLeft: 26, marginRight: 26, marginBottom: 4, marginTop: 4, backgroundColor: '#eee' }}></div>
    </>

    return <>
        <div className="fill-available" style={{ height: 1, marginBottom: 18, marginTop: 18, backgroundColor: '#eee' }}></div>

        <div style={styles.advantage_title}>{intl.formatMessage({ id: 'advantage_of_product' }, { product: capitalizeFirstLetter(sku.service_level) })}</div>

        {services.includes('aiinsights') && <>
            {generateCardSection}
            {advancedFeaturesSection}
            {askAISection}
            {memosSection}
        </>}

        {!services.includes('aiinsights') && sku.service_level !== 'basic' && <>
            {askAISection}
            {memosSection}
            {advancedFeaturesSection}
            {generateCardSection}
        </>}

        {!services.includes('aiinsights') && sku.service_level === 'basic' && <div>
            <div style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
            }}>
                {askAISection}
                {memosSection}
            </div>
            {advancedFeaturesSection}
        </div>
        }

        {
            sku.service_level !== 'basic' && <>
                <div style={styles.advantage_title}>{intl.formatMessage({ id: 'other_benefits' })}</div>
                <PrivilegeItem text={intl.formatMessage({ id: 'no_traffic_control' })} />
                <PrivilegeItem text={intl.formatMessage({ id: 'technical_support' })} />
            </>
        }
        {
            !!sku.promote_text &&
            <div style={{ fontSize: 13, color: '#666', paddingTop: '4px', paddingBottom: '6px' }}> {sku.promote_text}</div>
        }
    </>
}

const styles = {
    advantage_title: {
        fontWeight: 600,
        fontSize: 15,
        paddingTop: 8,
        paddingBottom: 8
    }
}

export default PrivilegesAI