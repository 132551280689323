import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { LOGIN_ACTIONS, SIDE_BAR_SHOW, SETTINGS } from 'src/constants/actionTypes';
import { ChevronLeft, ChevronRight, More } from '@styled-icons/material-outlined';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Divider, Tooltip } from '@mui/material';
import { blue } from '@mui/material/colors';
import { useHistory, useLocation } from 'react-router-dom'

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { fetchOrgs, logout_of_server, switchWorkspace } from 'src/actions/ticketAction';
import { getStateByUser } from 'src/reducers/listReducer';
import { Check, KeyboardArrowDown } from '@styled-icons/material';
import { FaPlus } from 'react-icons/fa';
import { maskUsername } from '../../utils/maskUsername';

const Profile = ({ showSidebarSwitch, style, switchOnly }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const sidebarShow = useSelector((state) => state.uiState.sidebarShow);
    const loginUser = useSelector(state => state.loginIn.user);

    const orgs = useSelector(state => getStateByUser(state.org_lists, loginUser));
    const workingSpace = orgs.items.find(org => org._id === loginUser.workingOrgId) || {};

    const [itemHovered, setItemHovered] = useState();
    const [triggerHovered, setTriggerHovered] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return <div
        style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignContent: 'center',
            alignItems: 'center',
            height: '36px',
            width: '100%',
            fontSize: 15,
            fontWeight: 'bold',
            ...style
        }}
    >
        <Tooltip title={'Switch workspace'}>
            <div
                style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer', maxWidth: '100%',
                    backgroundColor: triggerHovered ? '#f3f3f3' : undefined,
                }}
                onClick={handleClick}
                onMouseEnter={() => setTriggerHovered(true)}
                onMouseLeave={() => setTriggerHovered(false)}
            >
                {
                    workingSpace &&
                    <Avatar sx={{ bgcolor: blue[500], width: 28, height: 28, marginLeft: 1, marginRight: sidebarShow ? 1 : 0 }} variant="rounded">
                        {workingSpace.name ? workingSpace.name[0].toUpperCase() : 'U'}
                    </Avatar>
                }
                {
                    sidebarShow &&
                    <div
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {
                            workingSpace.name
                        }
                    </div>
                }
                {
                    sidebarShow &&
                    <div style={{ width: 24 }}>
                        <KeyboardArrowDown size={20} />
                    </div>
                }
            </div>
        </Tooltip>

        {
            (showSidebarSwitch || !sidebarShow) &&
            <div className='btn-transparent'
                onClick={(event) => {
                    dispatch({ type: SIDE_BAR_SHOW, value: !sidebarShow });
                    event.preventDefault();
                    event.stopPropagation();
                }}
            >
                {
                    !sidebarShow ? <ChevronRight style={{ width: '22px', height: '22px' }} /> : <ChevronLeft style={{ width: '22px', height: '22px' }} />
                }
            </div>
        }
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 0, paddingLeft: 10, paddingRight: 5 }}>
                <span style={{ textSize: 14, color: 'grey', fontFamily: '"Roboto","Helvetica","Arial",sans-serif;' }}>
                    {maskUsername(loginUser.username)}
                </span>
                {
                    !switchOnly &&
                    <Tooltip title={intl.formatMessage({ id: 'create_or_join_space' })} placement="bottom-end" arrow>
                        <div
                            className='hoverStand'
                            style={{ paddingTop: 5, paddingBottom: 5, margin: 0 }}
                            onClick={(event) => {
                                history.push({ pathname: '/newworkspace', state: { from: 'profile' } });
                                event.preventDefault();
                                event.stopPropagation();
                            }}>
                            <FaPlus size={14} style={{ padding: '1px 8px', color: 'grey', cursor: 'pointer' }} />
                        </div>
                    </Tooltip>
                }
            </div>
            {
                orgs.items && orgs.items.length > 0 && orgs.items.map((org, index) => {
                    return <MenuItem
                        key={index}
                        onClick={() => {
                            dispatch(switchWorkspace({ orgId: org._id }, null, 'profile'));
                            handleClose();
                        }}

                        onMouseEnter={() => setItemHovered(index)}
                        onMouseLeave={() => setItemHovered(null)}
                    >
                        <div
                            style={{
                                // backgroundColor: loginUser.workingOrgId === org._id ? '#f0f0f0' : (index == itemHovered ? '#f5f5f5' : 'transparent'),
                                width: 300,
                                display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                                color: '#333',
                                fontSize: 15
                                // marginBottom: '1px',
                                // cursor: 'pointer',
                                // padding: 4,
                                // paddingLeft: 14,
                                // paddingRight: 14
                            }}
                        >
                            {org.name}
                            {
                                loginUser.workingOrgId === org._id &&
                                <Check size={18} />
                            }
                        </div>
                    </MenuItem>
                })
            }
            <Divider />
            <MenuItem onClick={() => {
                dispatch({ type: SETTINGS.RESET_CACHE });
            }}><span style={{ fontSize: 13, color: 'gray' }}>Clear Cache</span></MenuItem>
            <MenuItem onClick={() => {
                dispatch(logout_of_server({}));
                handleClose();
            }}><span style={{ fontSize: 13, color: 'gray' }}>{intl.formatMessage({ id: 'logout' })}</span></MenuItem>
        </Menu>
    </div>
}

export default Profile;