import { useDispatch, useSelector } from "react-redux";
import { getServingProducts, getAvailableProducts, initSubscription, cancelSubscription } from "src/actions/ticketAction";
import { useIntl } from "react-intl";
import { useEffect, useMemo, useState } from "react";
import { BILL_PERIOD_DIALOG, SERVING_PRODUCT, STRIPE_CHECKOUT_DIALOG } from "src/constants/actionTypes";
import { formatDate } from "src/utils/timeFormater";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { StripeCheckoutModal } from "./StripeCheckoutModal";
import PrivilegesFunBlocks from "./PrivilegesFunBlocks";
import PrivilegesAI from "./PrivilegesAI";
import { useMediaQuery } from "react-responsive";
import { MOBILE_MEDIA_QUERY } from "../../utils/constants";

const capitalizeFirstLetter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
}

const getQuotaText = (quota, intl) => {
    return quota?.value || quota?.desc && intl.formatMessage({ id: quota?.desc }) || quota
}

const SkuCard = ({ sku, service, serving_product = {}, privileges_section }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [loading, setLoading] = useState();

    const trial_days = serving_product?.name == 'free' && !serving_product.latestSubscription && sku.trial_days;
    const canUpgrade = sku.promotion_priority > serving_product?.sku?.promotion_priority
    const bill_title = service && intl.formatMessage({ id: 'checkout_form_title_' + service }, { level: sku.service_level });
    const isBrightestProduct = sku.productId.includes('unlimited');
    const loginUser = useSelector(state => state.loginIn && state.loginIn.user);

    const handleSubscription = (service, sku) => {
        if (!loginUser?._id) {
            const hash = window.location.hash;
            let queryString = '';
            const queryIndex = hash.indexOf('?');
            if (queryIndex !== -1) {
                queryString = hash.substring(queryIndex);
            }

            window.open(window.location.origin + '/#/login' + queryString, '_blank')
            return
        }

        dispatch({
            type: STRIPE_CHECKOUT_DIALOG,
            value: {
                visible: true,
                skus: [sku],
                service,
                title: bill_title,
                trial_days
            }
        })
    }
    const handleCancelSubscription = (service, subscriptionId) => {
        setLoading({ service })
        dispatch(cancelSubscription({ subscriptionId }, (servingProduct) => {
            setLoading(null);

            !!servingProduct && dispatch({
                type: SERVING_PRODUCT,
                item: servingProduct
            })
            // if (data?.refreshServingProducts) {
            //     return dispatch(getServingProducts({ services }, null, 'servicesubscribe'));
            // }
        }, () => setLoading(null)))
    }

    // console.log('serving product.........', sku, serving_product)

    const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

    return (
        <div style={{
            border: '1px solid #ddd',
            borderRadius: 16,
            width: '100%',
            flex: 1,
            padding: 10,
            position: 'relative',
            color: '#333',
            background: isBrightestProduct ? 'linear-gradient(to bottom, #fa90e6, #fff)' : undefined,
            marginBottom: isMobile ? 20 : 0
        }}>
            {sku.discount && (
                <div style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    border: 'dodgerblue',
                    borderRadius: '0px 16px 0px 16px',
                    backgroundColor: isBrightestProduct ? 'chartreuse' : 'pink',
                    fontWeight: 'bold',
                    padding: isMobile ? 6 : 12,
                    paddingTop: isMobile ? 3 : 6,
                    paddingBottom: isMobile ? 3 : 6,
                    fontSize: isMobile ? 12 : 14
                }}>
                    {intl.formatMessage({ id: 'cost_save' })} {sku.discount} %
                </div>
            )}
            <div style={{
                marginTop: isMobile ? 10 : 20,
                fontSize: isMobile ? 20 : 24,
                fontWeight: 600,
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column'
            }}>
                {sku.title}
            </div>

            <div style={{
                display: 'flex',
                flexDirection: sku.service_level !== 'basic' || isMobile ? 'column' : 'row',
                justifyContent: 'center',
                columnGap: 30
            }}>
                <div style={{
                    marginTop: 20,
                    fontSize: isMobile ? 32 : 40,
                    fontWeight: 600,
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    ${sku.price}&nbsp;
                    <span style={{ fontSize: isMobile ? 20 : 24, whiteSpace: 'nowrap' }}> / {sku.period}</span>
                </div>

                <div style={{
                    height: isMobile ? 25 : 30,
                    marginTop: isMobile ? 10 : 15,
                    fontSize: isMobile ? 16 : 20,
                    fontWeight: 600,
                    color: '#888',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignSelf: sku.service_level !== 'basic' || isMobile ? undefined : 'center'
                }}>
                    ({sku.price && `$${(sku.price / (sku.recurring == 'monthly' ? 30 : 365)).toFixed(2)} / ${intl.formatMessage({ id: 'day' }).toLowerCase()}`})
                </div>
            </div>

            <div className="fill-available" style={{ minHeight: isMobile ? 50 : 60, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end' }}>
                {sku.productId.includes(serving_product.name) && sku.productId.includes(serving_product.service) && (
                    <div style={{ textAlign: 'center' }}>
                        <span style={{ fontSize: isMobile ? 11 : 13, color: '#666' }}>
                            {intl.formatMessage({ id: 'current_plan_expire_at' }, { date: formatDate(serving_product.endAt, 'YYYY-MM-DD'), plan: capitalizeFirstLetter(serving_product.name) })}
                        </span>
                        <LoadingButton
                            variant="text"
                            color="primary"
                            style={{ borderRadius: 14, paddingTop: 1, paddingBottom: 1, textTransform: 'capitalize', fontSize: isMobile ? 12 : 14 }}
                            disabled={!!loading}
                            loading={loading?.service === service}
                            onClick={() => handleCancelSubscription(service, serving_product.subscriptionId)}
                        >
                            {intl.formatMessage({ id: 'cancel' })}
                        </LoadingButton>
                    </div>
                )}
                {(!sku.productId.includes(serving_product.name) || !sku.productId.includes(serving_product.service) || canUpgrade) && (
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        style={{ borderRadius: 10, paddingTop: 8, paddingBottom: 8, textTransform: 'capitalize', width: '100%', maxWidth: 300, fontWeight: 'bold', fontSize: isMobile ? 12 : 14 }}
                        disabled={!!loading}
                        loading={loading?.service === service}
                        onClick={() => handleSubscription(service, sku)}
                    >
                        {intl.formatMessage({ id: trial_days ? 'trial_vip' : (canUpgrade ? 'upgrade' : 'subscribe') }, { trial_days })}
                    </LoadingButton>
                )}
            </div>

            {privileges_section}
        </div>
    );
}

const ServiceSubscribePanel = ({ services, service }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const available_products = useSelector(state => state.uiState.available_products);
    const serving_products = useSelector(state => state.uiState.serving_products) || [];
    const [pay_recurring, set_pay_recurring] = useState('yearly')
    const [skus, setSkus] = useState([]);
    const [serving_product, set_serving_product] = useState()
    const loginUser = useSelector(state => state.loginIn && state.loginIn.user);
    const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

    useEffect(() => {
        dispatch(getAvailableProducts({ services }, null, 'servicesubscribe'));
    }, []);

    useEffect(() => {
        loginUser?._id && dispatch(getServingProducts({ services }, null, 'servicesubscribe'));
    }, [loginUser]);

    useEffect(() => {
        if (serving_products) {
            let servingProducts = serving_products.filter(sp => service && services.includes(service) || services.includes(sp.service));

            let servingProduct = servingProducts.sort((a, b) => b.priority - a.priority)[0];
            if (servingProduct) {
                servingProduct.sku = skus.find(sku => sku.productId.includes(servingProduct.name));
            }

            set_serving_product(servingProduct);
        }
    }, [serving_products, skus])

    useEffect(() => {
        if (!available_products?.products) {
            return;
        }

        let skus = available_products.skus;

        Object.keys(available_products.products).forEach(service => {
            Object.keys(available_products.products[service]).forEach(level => {
                if (level == 'free') {
                    return;
                }

                let sku_monthly = skus.find(sku => sku.productId == `${service}_${level}_1`)
                let sku_yearly = skus.find(sku => sku.productId == `${service}_${level}_12`)

                sku_yearly.discount = Math.ceil((1 - sku_yearly.price / (sku_monthly.price * 12)) * 100)
            })
        })

        setSkus(skus.filter((sku, index, self) => index === self.findIndex(s => s.service_level === sku.service_level && s.recurring === sku.recurring)));
    }, [available_products]);

    const highest_discount = useMemo(() => skus?.reduce((max, sku) => (sku.discount > max ? sku.discount : max), 0), [skus])
    const basic_sku = useMemo(() => skus.find(sku => sku.recurring === pay_recurring && sku.service_level === 'basic'), [skus, pay_recurring]);
    const ai_skus = useMemo(() => skus.filter(sku => sku.recurring === pay_recurring && (sku.service_level !== 'basic')), [skus, pay_recurring]);

    const basic_sku_card = useMemo(() => {
        if (!basic_sku) {
            return;
        }

        let service = basic_sku.productId.split('_')[0];
        let privileges_primitive = available_products?.products && available_products?.products[service] && available_products?.products[service][basic_sku.service_level]?.privileges_primitive;

        return (
            <SkuCard
                key={basic_sku.productId}
                sku={basic_sku}
                service={service}
                serving_product={serving_product}
                privileges_section={
                    <PrivilegesAI
                        services={services}
                        privileges_primitive={privileges_primitive}
                        getQuotaText={getQuotaText}
                        capitalizeFirstLetter={capitalizeFirstLetter}
                        sku={basic_sku}
                    />
                }
            />
        )
    }, [basic_sku, available_products, serving_product])

    if (!available_products || !available_products.privileges) {
        return <></>
    }

    return (
        <div className="fill-available" style={{ columnGap: '10px', marginBottom: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <div style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                cursor: 'pointer',
                backgroundColor: '#eaeaea',
                borderRadius: 20,
                padding: 2,
                marginTop: 30,
                width: isMobile ? '100%' : 'auto'
            }}>
                <div style={{
                    padding: 10,
                    paddingBottom: 10,
                    borderRadius: 20,
                    width: isMobile ? '100%' : 'fit-content',
                    minWidth: isMobile ? undefined : '200px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: pay_recurring === 'yearly' ? 'skyblue' : undefined,
                    color: pay_recurring === 'yearly' ? 'white' : undefined,
                    marginBottom: isMobile ? 5 : 0
                }} onClick={() => set_pay_recurring('yearly')}>
                    {intl.formatMessage({ id: 'yearly' })} ({intl.formatMessage({ id: 'cost_save' })} {highest_discount}%)
                </div>
                <div style={{
                    padding: 10,
                    paddingBottom: 10,
                    borderRadius: 20,
                    width: isMobile ? '100%' : 'fit-content',
                    minWidth: isMobile ? undefined : '200px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: pay_recurring === 'monthly' ? 'skyblue' : undefined,
                    color: pay_recurring === 'monthly' ? 'white' : undefined
                }} onClick={() => set_pay_recurring('monthly')}>
                    {intl.formatMessage({ id: 'monthly' })}
                </div>
            </div>

            <div className="fill-available" style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                columnGap: isMobile ? '0' : '3%',
                rowGap: isMobile ? '20px' : '0',
                padding: isMobile ? 0 : 30,
                paddingTop: 30,
                paddingLeft: 0,
                paddingRight: 0,
                width: '100%'
            }}>
                {ai_skus?.map(sku => {
                    let service = sku.productId.split('_')[0];
                    let privileges_primitive = available_products?.products && available_products?.products[service] && available_products?.products[service][sku.service_level]?.privileges_primitive;
                    return (
                        <SkuCard
                            key={sku.productId}
                            sku={sku}
                            service={service}
                            serving_product={serving_product}
                            privileges_section={
                                service == 'funblocks' ?
                                    <PrivilegesFunBlocks
                                        privileges_primitive={privileges_primitive}
                                        getQuotaText={getQuotaText}
                                        capitalizeFirstLetter={capitalizeFirstLetter}
                                        sku={sku}
                                    /> :
                                    <PrivilegesAI
                                        services={services}
                                        privileges_primitive={privileges_primitive}
                                        getQuotaText={getQuotaText}
                                        capitalizeFirstLetter={capitalizeFirstLetter}
                                        sku={sku}
                                    />
                            }
                        />
                    )
                })}
            </div>

            {
                basic_sku_card
            }
            <StripeCheckoutModal />
        </div>
    );
}

export default ServiceSubscribePanel;