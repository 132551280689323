
import { KeyboardReturn } from '@styled-icons/material/KeyboardReturn';
import { useEffect, useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormLabel, Popover, Radio, RadioGroup, TextareaAutosize } from "@mui/material";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { IMAGE_UPLOAD_DIALOG, LINK_INPUT_DIALOG, OPERATION_FAILED } from 'src/constants/actionTypes';
import { PROMOTE_VIP } from '../../constants/actionTypes';
import PromoteVIP from '../settings/PromoteVIP';
import { Close } from '@styled-icons/material/Close';

export const PromoteModal = ({ }) => {
    const intl = useIntl();
    const dialogState = useSelector(state => state.uiState.promote_vip) || { visible: false };
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch({ type: PROMOTE_VIP, value: { visible: false } });
    }

    return (
        <Dialog
            open={!!dialogState && dialogState.visible}
            onClose={handleClose}
            scroll='paper'
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            maxWidth='md'
            style={{
                zIndex: 100,
            }}
        >

            <DialogContent dividers={true} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: 400, padding: 0, backgroundColor: 'white' }}>
                <div style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 101 }}>
                    <Close
                        size={24}
                        onClick={handleClose}
                        style={{ cursor: 'pointer', color: '#666' }}
                    />
                </div>
                <div style={{ width: '-webkit-fill-available', margin: '14px', marginTop: '38px', marginBottom: '20px', marginRight: '20px', rowGap: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                    {/* <div style={{ display: 'flex', flexDirection: 'row', columnGap: '6px', alignItems: 'center' }}>
                        <div style={{ width: 40, textAlign: 'right' }}>{intl.formatMessage({ id: 'link' })}</div>
                        <input
                            id="link"
                            type="url"
                            required
                            value={data.link || ''}
                            onChange={(event) => setData({
                                ...data,
                                link: event.target.value
                            })}
                            style={{
                                padding: '4px',
                                width: '-webkit-fill-available',
                                outline: 'none',
                                border: 'solid 1px lightgray', borderRadius: '3px'
                            }}
                            autoFocus={!dialogState.textEnabled}
                        />
                    </div> */}
                    <div style={{ display: 'flex', flexDirection: 'row', columnGap: '6px', alignItems: 'center' }}>
                        <PromoteVIP />
                    </div>
                </div>
                {/* <div style={{ paddingRight: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%', marginBottom: '8px', columnGap: '10px' }}>
                    <Button variant='text' onClick={handleClose}>{intl.formatMessage({ id: 'cancel' })}</Button>
                    <Button variant='contained' onClick={handleConfirm}>{intl.formatMessage({ id: 'confirm' })}</Button>
                </div> */}
            </DialogContent>
        </Dialog>
    );
}