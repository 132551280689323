import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { AI_API_MODEL, LLM_API_KEYS, LLM_API_KEY_MODAL } from '../../constants/actionTypes';
import { getAIModels } from '../../actions/ticketAction';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import { Selector } from '../common/Selector';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MEDIA_QUERY } from '../../utils/constants';

const llm_providers = {
    openai: { label: 'OpenAI (ChatGPT)', name: 'OpenAI', endpoint: 'https://api.openai.com/v1', url: 'https://platform.openai.com/account/api-keys' },
    anthropic: { label: 'Anthropic (Claude)', name: 'Anthropic', endpoint: 'https://api.anthropic.com/v1', url: 'https://console.anthropic.com/account/keys' },
    gemini: { label: 'Google (Gemini)', name: 'Gemini', endpoint: 'https://generativelanguage.googleapis.com/v1beta', url: 'https://makersuite.google.com/app/apikey' },
    deepseek: { label: 'DeepSeek', name: 'DeepSeek', endpoint: 'https://api.deepseek.com', url: 'https://platform.deepseek.com/api_keys' },
    groq: { label: 'Groq', name: 'Groq', endpoint: 'https://api.groq.com/openai/v1', url: 'https://console.groq.com/keys' },
    openai_compatible: { label: 'OpenAI Compatible API', name: 'openai_compatible', endpoint: '' }
}

const maskToken = (token) => {
    if (!token || token.length <= 8) return token;
    return token.slice(0, 4) + '*'.repeat(token.length - 8) + token.slice(-4);
};

export const LlmAPIKeyModal = ({ }) => {
    const intl = useIntl();
    const dialogState = useSelector(state => state.uiState.llmApiKeyDialog);
    const appConfigs = useSelector(state => state.uiState.app_config);
    const llm_api_keys = useSelector(state => state.uiState.llm_api_keys);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState();
    const [data, setData] = useState();
    const [models, set_models] = useState([])
    const [model_options, set_model_options] = useState();
    const operationStatus = useSelector(state => state.operationStatus);
    const [err_msg, set_err_msg] = useState();
    const [token_valid, set_token_valid] = useState();

    useEffect(() => {
        let options = models?.map(model => ({ label: model, value: model })) || [];
        options.push({ label: intl.formatMessage({ id: 'other' }), value: 'other' })
        set_model_options(options)
    }, [models, intl])

    useEffect(() => {
        if (dialogState?.visible && dialogState.item) {
            setData(dialogState.item)
        } else {
            setData({
                provider: 'openai',
                endpoint: llm_providers['openai']?.endpoint
            })
        }
    }, [dialogState])

    useEffect(() => {
        if (!operationStatus?.inProgress) {
            setLoading(null);
        }
    }, [operationStatus])

    const handleClose = (confirmedModelId) => {
        dispatch({ type: LLM_API_KEY_MODAL, value: { visible: false, confirmedModelId } });
    }

    const handleConfirm = useCallback(() => {
        if (!data.name || !data.token || !data.endpoint || !data.model || data.model === 'other') {
            set_err_msg(intl.formatMessage({ id: 'missing_required_data' }))
            return;
        }

        const id = data.id || new Date().getTime();
        const updatedKeys = llm_api_keys?.map(key => {
            if (key.id === id) {
                return {
                    ...key,
                    ...data
                };
            }
            return key;
        }) || [];

        if (!llm_api_keys?.some(key => key.id === id)) {
            updatedKeys.push({
                ...data,
                id,
                level: 'private',
            });
        }

        dispatch({
            type: LLM_API_KEYS,
            value: updatedKeys
        })

        dispatch({
            type: AI_API_MODEL,
            value: id
        })

        setData({});
        handleClose(id);
    }, [llm_api_keys, data])

    const getModelsOrTestToken = useCallback(() => {
        if (!data?.token || !data?.endpoint) {
            return;
        }

        setLoading({ action: 'getAIModels' });

        let provider = data.provider;

        if (provider == 'anthropic') {

        } else {
            dispatch(getAIModels(provider, data.endpoint, data.token, (list) => {
                if (!list) {
                    return;
                }

                set_token_valid('success');
                // console.log('list...................', provider, list)

                // if (provider === 'openai') {
                //     list = list.filter(item => item.owned_by == 'openai');
                // }

                if (appConfigs?.ai_providers && appConfigs?.ai_providers[provider]?.models) {
                    list = list.filter(item =>
                        appConfigs.ai_providers[provider]?.models?.some(model => item[provider !== 'gemini' ? 'id' : 'name']?.startsWith(model))
                        && !appConfigs.ai_providers[data.provider]?.excluded_models?.some(model => item[provider !== 'gemini' ? 'id' : 'name']?.startsWith(model))
                    )
                }

                list = list.map(item => provider !== 'gemini' ? item.id : item.name)

                if (!list || list.length == 0) {
                    return;
                }

                set_models(list);
                setData({
                    ...data,
                    model: list[0]
                })
            }, () => {
                set_token_valid('failed');
            }))
        }
    }, [data, appConfigs])

    const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

    return (
        <Dialog
            open={!!dialogState && dialogState.visible}
            onClose={handleClose}
            scroll='paper'
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            fullScreen={isMobile}
            maxWidth='md'
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px', paddingLeft: '20px', fontWeight: 500, fontSize: 18 }}>
                {intl.formatMessage({ id: 'add_private_llm_key' })}
            </div>

            <DialogContent dividers={true} style={{ width: isMobile ? '100%' : '638px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 20, paddingTop: 0, backgroundColor: 'white', overflowX: 'hidden' }}>
                <div style={{ width: '100%', alignItems: 'center', justifyContent: 'flex-start' }}>
                    {renderFormField('name', 'name', 'text')}
                    {renderFormField('provider', 'settings_api_llms', 'select', {
                        options: Object.values(llm_providers).map(provider => ({
                            label: provider.label,
                            value: provider.name.toLowerCase()
                        })),
                        onChange: (value) => {
                            setData(prevState => ({
                                ...prevState,
                                provider: value,
                                endpoint: llm_providers[value]?.endpoint
                            }));
                            set_token_valid('');
                            set_err_msg('');
                        }
                    })}
                    {renderFormField('endpoint', 'settings_api_proxy', 'text')}
                    {renderFormField('token', 'llm_api_key', 'text', { onBlur: getModelsOrTestToken })}

                    {renderProviderInfo()}
                    {renderTokenValidation()}

                    {renderFormField('model', 'settings_api_choose_model', 'select', {
                        options: model_options,
                        customInput: !models?.includes(data?.model)
                    })}

                    <div style={{ color: '#888', fontSize: '14px', marginTop: 16 }}>
                        <Markdown remarkPlugins={[remarkGfm]}>
                            {intl.formatMessage({ id: 'settings_api_desc' }, { provider: llm_providers[data?.provider]?.label })}
                        </Markdown>
                    </div>
                    {err_msg && (
                        <div style={{ color: 'red', fontSize: '14px', marginTop: 10 }}>
                            {err_msg}
                        </div>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <div style={{ display: 'flex', flexDirection: 'row', paddingRight: '16px', columnGap: '20px' }}>
                    <Button variant='text' onClick={handleClose}>{intl.formatMessage({ id: 'cancel' })}</Button>
                    <Button variant='contained' onClick={handleConfirm}>{intl.formatMessage({ id: 'confirm' })}</Button>
                </div>
            </DialogActions>
        </Dialog>
    );

    function renderFormField(name, label, type, additionalProps = {}) {
        return (
            <div style={styles.item}>
                <span style={styles.title}>
                    {intl.formatMessage({ id: label })}
                </span>
                {type === 'select' ? (
                    <Selector
                        value={data?.[name]}
                        inputStyle={{
                            ...styles.selector,
                            backgroundColor: data?.token ? undefined : '#f9f9f9',
                            minWidth: 80
                        }}
                        onChange={(value) => {
                            setData(prevState => ({ ...prevState, [name]: value }));
                            set_err_msg('');
                            additionalProps.onChange && additionalProps.onChange(value);
                        }}
                        options={additionalProps.options}
                    />
                ) : (
                    <input
                        type={type}
                        value={name === 'token' ? maskToken(data?.[name]) : (data?.[name] || '')}
                        style={{
                            ...styles.input,
                            width: isMobile ? '100%' : '-webkit-fill-available',
                            minWidth: isMobile ? 'unset' : '400px',
                        }}
                        required={true}
                        onChange={(e) => {
                            const value = e.target.value;
                            setData(prevState => ({ 
                                ...prevState, 
                                [name]: value
                            }));
                            set_err_msg('');
                        }}
                        {...additionalProps}
                    />
                )}
                {additionalProps.customInput && !models?.includes(data?.model) && (
                    <input
                        type='text'
                        value={(!data?.[name] || data?.[name] == 'other') ? '' : data[name]}
                        style={{
                            ...styles.input,
                            width: isMobile ? '100%' : '-webkit-fill-available',
                            minWidth: isMobile ? 'unset' : '360px',
                        }}
                        required={true}
                        onChange={(e) => {
                            setData(prevState => ({ ...prevState, [name]: e.target.value }));
                            set_err_msg('');
                        }}
                    />
                )}
            </div>
        );
    }

    function renderProviderInfo() {
        if (!data?.provider || data.provider === 'openai_compatible' || !llm_providers[data.provider]) return null;

        return (
            <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex', marginTop: 3, flexWrap: 'wrap' }}>
                <div style={{ color: 'gray', fontSize: 14 }}>
                    {intl.formatMessage({ id: 'llm_api_token_guide' }, { aiProvider: llm_providers[data.provider].name })}
                </div>
                <div style={{ paddingLeft: 6, cursor: 'pointer', color: 'dodgerblue', fontSize: 15 }} onClick={() => {
                    window.open(llm_providers[data.provider].url);
                }}>
                    {llm_providers[data.provider].name}
                </div>
            </div>
        );
    }

    function renderTokenValidation() {
        if (data?.provider === 'anthropic') return null;

        return (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 8 }}>
                <div
                    className='hoverStand'
                    style={{
                        color: loading?.action === 'getAIModels' || !data?.token || !data?.endpoint ? 'gray' : 'dodgerblue',
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: 6,
                        paddingTop: 4,
                        paddingBottom: 4,
                        columnGap: 8,
                        fontSize: 15
                    }}
                    onClick={() => !(loading?.action === 'getAIModels' || !data?.token || !data?.endpoint) && getModelsOrTestToken()}
                >
                    {loading?.action === 'getAIModels' && <CircularProgress size={15} />}
                    {intl.formatMessage({ id: 'get_models_test_token' })}
                </div>
                {token_valid && (
                    <div style={{
                        color: token_valid === 'failed' ? 'red' : 'green',
                        paddingRight: 14
                    }}>
                        {intl.formatMessage({ id: token_valid === 'failed' ? 'token_invalid' : 'token_valid' })}
                    </div>
                )}
            </div>
        );
    }
};

const styles = {
    item: {
        flexDirection: 'row',
        alignItems: 'center',
        display: 'flex',
        paddingTop: '20px',
        columnGap: '10px',
    },
    title: {
        whiteSpace: 'nowrap',
        fontWeight: 500,
        fontSize: 16
    },
    input: {
        padding: "3px 12px",
        border: '1px solid #ccc',
        borderRadius: 15,
        outline: 'none',
        fontSize: 14,
    },
    selector: {
        border: '1px solid #ccc',
        paddingLeft: 12,
        paddingRight: 6,
        borderRadius: 15,
    }
};