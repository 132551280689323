export default {
  untitled: 'Untitled',
  title: 'Title',
  sidebarTitle: 'FunBlocks',
  team_space: `'s Team Space`,
  personal_space: `'s Personal Space`,
  workspace: 'WorkSpace',
  workspace_tooltip: 'Team members can access these pages',
  private: 'Private',
  private_tooltip: 'Only you can access these pages',
  shared: 'Shared',
  shared_tooltip: 'Only people you shared can access these pages',
  ril: 'Read Later',
  share: 'Share',
  back: 'Back',
  close: 'Close',
  cancel: 'Cancel',
  confirm: 'Confirm',
  confirm_done: 'Done',
  open: 'Open',
  create: 'Create',
  loadmore: 'Load More',
  new_page: 'New Page',
  refresh: 'refresh',

  get_title: 'Get title',

  more_menu: 'Click to have more actions',
  export: 'Export',
  export_formats_doc: 'PDF, Word, Markdown, Html',
  export_formats_db: 'CSV',
  export_formats_slides: 'PDF, Markdown',
  export_format: 'Export format',
  export_no_data: 'No data to export',
  export_slides_notes: 'Speaker notes',
  excluded: 'excluded',
  separated: 'separated page',
  same_page: 'same page',

  import: 'Import',
  import_settings: 'Settings: ',
  csv_header_confirm: 'Use the csv header as the database header',
  csv_column: 'Column {num}',
  csv_first_line: 'First line in the CSV file: ',

  error_msg: 'Something went wrong!',

  invite_code: 'Invite Code',
  invite_friends: 'Invite Friends',
  invite_friends_desc: 'reward AI tokens',
  or_invite_friend_rewards: 'or get rewards through',
  copy_intruction: 'Copy following text and send to friends:',
  homepage: 'Home page',
  feedback: 'Feedback',
  reply: 'Reply',
  notice: 'Notice',
  new_version_available: 'New version available, click button below to update',
  upgrade_now: 'Update now',
  upgrade_plan: 'Upgrade Now',
  err_catched: 'An app error catched, click button below to reload',
  reload: 'Reload',

  new_tag: 'New Tag',

  slides: 'Slides',
  db: 'Data Source',
  doc: 'Page',
  flow: 'AI Flow',
  ai_flow: 'AI Whiteboard + Mindmap',
  ai_slides: 'AI Slides',
  ai_infographics: 'AI Infographics',
  ai_tools: 'FunBlocks AI Tools',

  notag_label: 'No {label}',

  display: 'Display',
  share: 'Share',
  edit: 'Edit',
  comment: 'Comment',
  full_access: 'Full Access',
  full_access_description: 'Can edit and share with others',
  can_edit: 'Can Edit',
  can_edit_description: 'Can edit, but not share with others',
  can_comment: 'Can Comment',
  can_comment_description: 'Can comment, but not edit',
  can_view: 'Can View',
  can_view_description: 'Cannot edit or share',

  open_to_public: 'Open to Public',
  everyone_at_org: 'Everyone at Space',
  everyone_at_group: 'Everyone at Group',

  copy_link: 'Copy Link',
  duplicate: 'Duplicate',
  copied: 'Copied to clipboard',
  copy_failed: 'Failed to copy',

  history: 'Editing History',
  restore_doc_tip: 'Restore to this version? ',
  restore_doc_tooltip: 'This will overwrite the current content',
  no_history_doc_selected: 'No history version selected',
  doc_history: 'Page Editting History',

  doc_play: 'Convert to Slides',
  slides_seminar: 'Seminar mode',
  slides_show: 'Slides mode',
  slides_editor: 'Make Video',
  slides_ai_converter: 'Make Slide with AI',

  trashbin: 'Trash Bin',
  trashbin_tooltip: 'Drag pages here to delete them',
  ril: 'Read It Later',
  settings: 'Members and settings',
  settings_account: 'Account Settings',
  settings_workspace: 'Workspace Settings',

  settings_my_account_menu: 'My Account',
  settings_my_account_title: 'My Account',
  settings_ril_menu: 'Instant Notes',
  settings_ril_title: 'Read It Later Plugin Settings',
  settings_ril_workspace_selection: 'Select a workspace for instant notes from FunBlocks mobile App ',
  settings_flow_title: 'Flow Whiteboard Settings',
  settings_flow_menu: 'Whiteboard',
  settings_more_menu: 'More settings',
  settings_more_title: 'More Settings',
  settings_language_desc: 'Select your UI language',
  settings_default_query_type_desc: 'Default task type',
  flow_settings_brainstorming_req_clarify: 'Enable AI to clarify requirements for brainstorming tasks',
  settings_api_menu: 'LLM Service Providers',
  settings_api_title: 'Large language model(LLM) API Settings',
  settings_api_enable_api: 'Enable LLM API access',
  settings_api_llms: 'Providers',
  self_owned_ai_api: 'Private LLM API Keys',
  llm_api_key: 'API Key',
  llm_api_token_guide: 'You can login to {aiProvider} to get API Key',
  settings_api_choose_model: 'Choose a model',
  settings_api_proxy: 'Endpoint',
  openai_compatible:
    '### When using an LLM service compatible with the OpenAI interface, simply follow these steps:\n1. Enter the interface address in the "Endpoint" field;\n2. Choose the "Other" from "models" and provide the model name.',
  other: 'Other',
  settings_api_desc: '### After enabling and setting 3rd party LLM API key: \n1. FunBlocks AI will directly access the LLM with the API Key you provided, without the limitation of the number of request to FunBlocks AI. \n2. The API key is saved locally and will not be uploaded to the FunBlocks server, so there is no need to worry about being stolen.',
  invalid_api_settings: 'It appears you have initiated integration with a 3rd party LLM service but have yet to finalize the requisite configurations. Please re-examine the settings to ensure proper completion.',
  has_ai_api: 'Has LLM API key?',
  settings_prompts_menu: 'AI Apps/Prompts',
  settings_prompts_title: 'AI Assistant Apps',
  add_prompt: 'Create App',
  task_content: 'Variables input',
  task_content_from_selected: 'Selected text from page',
  task_content_from_input: 'Text input',
  task_content_from_form: 'Custom form fields',
  advanced_options: 'Advanced options',
  context_usage: "Use/Process Context",
  context_usage_desc: "When the AI assistant executes a prompt, it will automatically choose and use the context based on the application scenario. You can also specify how to use the context in the prompt by including '{{selected_text}}'. For example: 'Given the received message or comment content: {{selected_text}}.\nPlease read the above content, understand the sender's intent, and write a reply that addresses the sender's intent and other requirements.'",
  task_content_form: "Custom Prompt Input Fields",
  task_content_form_desc: "When executing a prompt, the AI needs to understand the user's specific requirements. Breaking these requirements into multiple input fields helps users understand and input their needs, and allows the AI to understand them more accurately, resulting in more satisfactory outcomes.",
  ask_ai_about_selected: 'Given text',
  ask_ai_about_selected_tooltip: 'Ask AI with given text as context',
  ask_ai_about_given_text_hint: 'Please enter or paste the text to be processed',

  prompt_title: 'Title',
  prompt_title_placeholder: `Use a descriptive title to help users understand the prompt's function`,
  developer: 'Developer',
  description: 'Description',
  prompt_desc_placeholder: 'A description of this AI App/Prompt, for example, what it can do, etc...',
  prompt_template: 'Prompt template',
  prompt_template_desc: "The prompt for AI, which could contain {selected_text}{variables}. During AI execution, {selected_text} will be replaced with context you provide",
  prompt_template_desc_1: `When the AI assistant executes a prompt, it will automatically append the user's input to the prompt. Similar to '{{selected_text}}', you can also specify how to handle the user's input in the prompt with references to: {variables}`,
  prompt_template_placeholder: `Prompt, for example: I want you to act as a creative writing assistant. The given text is the first part of the article. Please understand the author's writing intention and continue to write the next paragraph. It is required to maintain a consistent style, coherent and logical content.`,
  prompt_template_fixed_placeholder: `Prompt, for example: Tell me a funny joke. `,
  prompt_no_text_err: 'Make sure to include {variables} in your prompt template',
  prompt_content_placeholder: 'Text for AI assistant',
  prompt_content_label: 'Topic',
  prompt_context: 'Prompt context',
  prompt_context_desc: 'Provide relevant content for the AI assistant to execute prompt',
  context_no_doc_choosen: 'Please choose a page for AI assistant as context',
  context_doc_no_content: 'The selected page has no readable content',
  context: 'Given context',
  current_doc: 'Current page',
  choose_a_doc: 'Choose a page',
  none: 'None',
  settings_space_menu: 'Settings',
  settings_space_title: 'Workspace Settings',
  settings_members_menu: 'Members',
  settings_labs_menu: 'Labs',
  settings_db_capability_switch: 'Database enabled',
  service_subscribe: 'Upgrade',
  service_subscribe_ai: 'Upgrade AI',
  service_subscribe_funblocks: 'Upgrade FunBlocks',
  service_subscribe_title: 'Upgrade plans',
  service_product_name: '{service} {level}',
  service_market: 'Buy AI Token',
  price: 'Price',
  num: 'Quantity',
  name: 'Name',
  subscribe: 'Subscribe Now',

  settings_affiliate_menu: 'Affiliate',
  affiliate_program_title: 'Join Our Affiliate Program',
  affiliate_program_description: 'Become a part of our Affiliate Program and earn rewards by sharing the growth of FunBlocks.',
  referred_users: 'Referred Users',
  payment_records: 'Payment Records',

  pin: 'Pin',
  pinned: 'Pinned',
  visibility: 'Visibile to',
  visibility_private: 'Only me (Private)',
  visibility_public: 'Everyone (Public)',
  visibility_workspace: 'Everyone (@workspace)',
  prompts_mine: "My Apps",
  prompts_mine_desc: 'You can create your own AI assistant apps here or choose from public apps that suit your needs.',
  prompts_developed: "My Apps (I've developed)",
  prompts_public: "Public Apps",
  prompts_workspace: 'Workspace Apps',
  prompts_pinned: "Pinned Apps",
  prompts_pinned_desc: "You can pin your favorite apps from public or shared apps, and the AI assistant will display your pinned apps in the menu, ready for you to execute at any time.",
  prompts_drafter: "FunBlocks AI apps",
  prompts_validate: "Validate Apps",
  prompt_qualified: "Qualified",
  prompt_not_qualified: "Not Qualified",
  prompt_no_arg: 'No variable in prompt',
  run_prompt: "Run",
  prompt_language: "App description language",
  all: 'All',
  arg_type: 'Type',
  arg_type_textline: 'Text',
  arg_type_input: 'Text',
  arg_type_text: 'Multiline text area',
  arg_type_textarea: 'Multiline text area',
  arg_type_select: 'Select',
  select_option_placeholder: 'Option for select',
  select_option_free_input: 'Allow users to enter values other than options',
  no_suitable_option: 'No suitable option',
  user_input_option: 'Input your own value',
  arg_name: 'Variable',
  arg_label: 'Title',
  arg_hint: 'Hint',
  arg_default_value: 'Default value',
  arg_required: 'Required',
  arg_optional: 'Optional',
  yes: 'Yes',
  no: 'No',
  arg_label_placeholder: 'Field Title',
  arg_hint_placeholder: 'Show user the appropriate content to fill in this field',
  arg_default_value_placeholder: 'Default value',
  add_arg: 'Add Field',
  clear_arg: 'Clear',
  update: 'Update',
  generate_outline: 'Generate outline with AI',
  no_topic: 'Please provide a topic to write about',
  prompt_blocked_safety: 'Contain dangerous or inappropriate information in task prompts',
  unknown_failure: 'Unknown error',

  prompt_app: 'FunBlocks Prompt Application',
  prompt_unpin_tooltip: 'Remove it from my AI assistant',
  prompt_pin_tooltip: 'Pin it to my AI assistant',
  prompt_clone_tooltip: 'Clone App',
  prompt_edit_tooltip: 'Edit App',
  prompt_delete_tooltip: 'Delete App',
  link_copied: 'Sharing link successfully copied to clipboard',
  prompt: 'AI Assistant Instruction',
  confirm_open_ai_generated_slides: 'The slideshow has been generated and saved in private space, continue to edit?',

  CRUD: 'Add/Update/Delete',
  not_provided: 'Not provided',
  new: 'New',
  clone: 'Clone',

  service_name_ril: 'FunNotes',
  service_name_funblocks: 'FunBlocks',
  service_name_xslides: 'Slides',
  service_name_ai: 'FunBlocks AI ',
  service_name_aiplus: 'AI',

  funblocks_product_slogan: 'Boost your productivity with FunBlocks',
  funblocks_promotion_slogan: 'We offer one of the most cost-effective solutions in the market!',
  funblocks_ai_product_slogan: 'Unleash your limitless potential with FunBlocks AI',
  funblocks_ai_promotion_slogan: 'We offer one of the most cost-effective solutions in the market!',
  funblocks_aiinsights_promotion_slogan: 'We offer the most creative AI tools in the market!',

  yearly: 'Annually',
  monthly: 'Monthly',
  cost_save: 'Save ',
  upgrade_to_vip: 'Upgrade your plan',
  purchase_ai: 'Purchase AI Premium',
  cancel_subscription: 'Cancel current plan',
  billed_period: 'Billing period',
  trial_vip: '{trial_days} days FREE trial',
  trial_desc: 'You will have a free trial of VIP plan for {trial_days} days, and there will be no charges during this period.',
  trial_confirm: 'Start Free Trial',
  wont_charge_today: "You won't be charged today",
  order_summary: 'Order Summary',
  upgrade: 'Upgrade',

  service_level_free: 'free',
  service_level_vip: 'VIP',
  service_level_plus: 'Plus',
  service_level_pro: 'Pro',
  service_level_premium: 'Premium',
  service_level_unlimited: 'Unlimited',
  service_level_smallteam: 'small team',
  service_level_enterprise: 'Enterprise',

  supports: 'Supports advanced AI models from',
  advantage_of_product: 'Advantages',
  generate_insights_cards: 'Generate AI insights cards',
  generate_insights_cards_quota: 'Daily generate AI Graphics: {quota} times',
  more_queries_per_day: 'More queries per day',
  query_quota: '{model_level}: {quota} queries',
  unlimited: 'Unlimited',
  advanced_features: 'Advanced features',
  writing_assistant: 'AI Writing Assistant',
  reading_assistant: 'AI Reading Assistant',
  other_llm_providers_enabled: 'Use your self-owned LLM API key to query the AI assistant',
  other_llm_providers_enabled_desc: 'Supports OpenAI GPT-3.5/4, Claude-3, Gemini APIs with no daily access restrictions.',
  build_ai_apps: 'Customize AI Assistant Prompts',
  other_benefits: 'Other benefits',
  technical_support: 'Priority technical support',
  no_traffic_control: 'No request limits during high-traffic periods',
  more_capacity: 'More space',
  flow_whiteboards_capacity: 'AIFlow Whiteboards: {quota}',
  flow_workspaces_capacity: 'Number of workspaces: {quota}',
  flow_flowNodes_capacity: 'Nodes per whiteboard: {quota}',
  memos_capacity: 'Daily Memo storage capacity: {quota}',
  memos_capacity_desc: 'Supports saving of content generated by the AI assistant to Memo. Also, allows saving of selected web page content to Memo.',
  blocks_capacity: 'Monthly block storage capacity: {quota}',
  blocks_capacity_desc: 'Block is the fundamental unit that makes up the content of FunBlocks pages. It can be text, images, tables, code snippets, or any other type of content.',
  team_size: 'Maximum members in workspace: {quota}',
  markdown_slides: 'Markdown Slides Writing',
  markdown_slides_desc: 'Supports creating slides using Markdown format, simple and efficient, equipped with an AI writing assistant to boost your productivity.',
  doc_to_slides: 'Convert Document to Slides by AI',

  checkout_form_title_aiplus: 'Get more powerful FunBlocks AI',
  checkout_form_title_funblocks: 'Upgrade to {level}',
  checkout_form_title_aiinsights: 'Get more powerful AI Insights',
  checkout_total: 'Total due today',
  sub_total: 'Subtotal',

  buyable_plans: 'Funblocks Plans',
  privileges: 'Privileged features',
  privileged_feature: 'This feature is privileged to VIP plan',
  current_plan: 'Current plan',
  plan_expire_at: 'Current plan will expire at: {date}',
  last_plan_expired: 'Your last {plan} plan expired at {date}, renew it now!',
  current_plan_expire_at: 'Your {plan} plan will expire on {date}',
  buy: 'Buy now',
  paid_to_check: 'Paid, refresh status',
  subscribe_status_updated: 'Subscription status updated',
  aicoins_status_updated: 'AI tokens balance updated',
  goto_vip_buy_page: 'Upgrade to VIP',
  aicoin: 'AI Coins',
  exceeded_quota: 'You have reached the current plan usage limit.',

  choose_login_method: "Choose Login Method",
  sign_in: 'Sign in to FunBlocks',
  sign_in_with: 'Sign in with {method}',
  sign_in_with_password: 'Sign up/Sign in using password',
  sign_in_with_email_vcode: 'Sign in with Email verification code',
  google: 'Google Account',
  password_account: 'Password',
  login_signin_form_info: 'Sign In to your account',
  login_signin_vcode_form_info: 'Login or create your FunBlocks account',
  login_resetpswd_form_info: 'Reset your password with verfication code',
  login_signup_form_info: 'Register your account to FunBlocks',
  forgotpswd: 'Forgot password?',
  hadaccount: 'Already have an account?',
  login: 'Login',
  vcode_login: 'Login with verification code',
  password_login: 'Login with password',
  logout: 'Logout',
  signup: 'Sign Up',
  resetpswd: 'Reset Password',
  not_registered: 'Not registered?',
  register_now: 'Register Now',
  signup_info: `Don't have a FunBlocks account yet? You can complete the registration in one minute, and immediately experience the fun of writing with FunBlocks AI.`,
  getvcode: 'Get Code',
  verification_code: 'Verification Code',
  vcode_err: `Code incorrect, if it's unclear, you can click it to refresh`,
  phone: 'Phone',
  email: 'Email',
  phone_or_email: 'Phone or Email',
  nickname: 'Nick name',
  captcha: 'Captcha',
  password: 'Password',

  rename: 'Rename',
  insert_right: 'Insert Right',
  insert_left: 'Insert Left',
  delete_property: 'Delete Property',
  confirm_delete_property: 'Confirm to delete property: {label}? Deleted property will also delete data under this property.',
  property_type: 'Property Type',
  hide_property: 'Hide Property',
  add_property: 'Add Property',

  confirm_delete_item: 'It cannot be restored after deletion. Are you sure to delete?',

  sort: 'Sort',
  sorts: 'Sorts',
  sort_ascending: 'Sort Ascending',
  sort_descending: 'Sort Descending',
  add_sort: 'Add Sort',
  delete_sort: 'Delete Sort',
  operation_need_to_delete_sort: 'Would you like to remove sorting?',
  asc: 'Ascending',
  desc: 'Descending',

  filter: 'Filter',
  filters: 'Filters',
  add_filter: 'Add Filter',
  delete_filter: 'Delete Filter',
  delete_filter_rule: 'Delete Filter Rule',
  delete_filter_group: 'Delete Filter Group',
  filter_where: 'Where',
  filter_and: 'And',
  filter_or: 'Or',
  add_filter_rule: 'Add Filter Rule',
  add_filter_group: 'Add Filter Group',
  turn_into_filter_group: 'Turn Into Group',

  equals: 'Is',
  not_equals: 'Is Not',
  less_than: 'Less Than',
  less_than_or_equal: 'Less Than Or Equal',
  greater_than: 'Greater Than',
  greater_than_or_equal: 'Greater Than Or Equal',
  contains: 'Contains',
  not_contains: 'Not Contains',
  starts_with: 'Starts With',
  ends_with: 'Ends With',
  is_empty: 'Is Empty',
  is_not_empty: 'Is Not Empty',
  is_true: 'Is True',
  is_false: 'Is False',
  date_greater_than: 'Is After',
  date_less_than: 'Is Before',
  date_greater_than_or_equal: 'Is on or after',
  date_less_than_or_equal: 'Is on or before',
  date_between: 'Is Within',

  type_title: 'Title',
  type_text: 'Text',
  type_number: 'Number',
  type_date: 'Date',
  type_person: 'Person',
  type_select: 'Select',
  type_multi_select: 'Multi-select',
  type_checkbox: 'Checkbox',
  type_email: 'Email',
  type_phone: 'Phone',
  type_url: 'URL',

  type_created_at: 'Created time',
  type_updated_at: 'Last edited at',
  type_created_by: 'Created By',
  type_updated_by: 'Last edited by',
  type_tags: 'Tags',

  advanced_type: 'Advanced',
  basic_type: 'Basic',

  view_config: 'View',
  view_settings: 'View Settings',
  timelineby: 'Timeline property',
  use_time_pair: 'Separate start and end dates',
  timeline_start: 'Start Date',
  timeline_end: 'End Date',
  timeline_color: 'Timeline color',

  info_set_timeline_title: 'No Date property configured for timeline view',
  info_set_timeline_content: 'Please configure timeline Date property in the view settings.',

  has_end_date: 'Has End Date',
  has_time: 'Include Time',
  date_format: 'Date Format',

  clear_cell_data: 'Clear',

  date_format_ymd_dash: 'YYYY-MM-DD',
  date_format_mdy_dash: 'MM-DD-YYYY',
  date_format_dmy_dash: 'DD-MM-YYYY',
  date_format_ymd_slash: 'YYYY/MM/DD',
  date_format_mdy_slash: 'MM/DD/YYYY',
  date_format_dmy_slash: 'DD/MM/YYYY',

  view_timeline_by_day: 'Day',
  view_timeline_by_week: 'Week',
  view_timeline_by_month: 'Month',
  view_timeline_by_year: 'Year',
  view_timeline_by_quarter: 'Quarter',
  today: 'Today',

  select_options: 'Options',
  add_option: 'Add Option',
  options_search_placeholder: 'Search for an option',
  option_input_placeholder: 'Type an option',
  option_delete: 'Delete Option',
  not_enough_options: 'Please add at least two options',
  not_null: '{field} can not be empty',

  color_whitesmoke: 'Whitesmoke',
  color_lightgray: 'Lightgray',
  color_skyblue: 'Skyblue',
  color_lightblue: 'Lightblue',
  color_azure: 'Azure',
  color_lightcyan: 'Lightcyan',
  color_honeydew: 'Honeydew',
  color_palegreen: 'Palegreen',
  color_pomonagreen: 'Pomonagreen',
  color_lightgreen: 'Lightgreen',
  color_lightyellow: 'Lightyellow',
  color_lavender: 'Lavender',
  color_lightpink: 'Lightpink',
  color_gold: 'Gold',
  color_yellow: 'Yellow',
  color_tomato: 'Tomato',
  color_beige: 'Beige',
  color_mistyrose: 'Mistyrose',

  color_red: 'Red',
  color_orange: 'Orange',
  color_green: 'Green',
  color_blue: 'Blue',
  color_purple: 'Purple',
  color_pink: 'Pink',
  color_brown: 'Brown',
  color_black: 'Black',
  color_white: 'White',
  color_gray: 'Gray',

  add_view: 'Add View',
  view_name_placeholder: 'Type view name',
  table: 'Table',
  board: 'Board',
  list: 'List',
  gallery: 'Gallery',
  timeline: 'Timeline',
  chart: 'Chart',
  create_view: 'Create View',
  delete_view: 'Delete View',
  default_status_name: 'Status',
  groups_shown: 'Visible groups',
  groups_hidden: 'Invisible groups',
  newpage: 'New Page',
  new_row: 'New',
  new_card: 'New card',

  row_item_label: 'Data item count',

  type: 'Type',
  line: 'Line',
  bar: 'Bar',
  pie: 'Pie',

  chart_axis: 'Axis',
  chart_datasets: 'Datasets',

  aggregate_to: 'Aggregate to',
  aggregate_to_individual: 'Each option',
  month: 'Month',
  week: 'Week',
  day: 'Day',
  quarter: 'Quarter',
  year: 'Year',

  add_board_lane: '',
  groupby_setting: 'Group',
  groupby_setting_title: 'Current view groups',
  groupby: 'Group By',
  delete_groupby: 'Remove Group',
  add_option_group: 'Add a group',

  properties_setting: 'Properties',
  properties_setting_title: 'Current view properties',
  all_properties: 'All Properties',

  account_not_activated: 'Your account is not activated, the activation email has been sent to your email: {email}, please check the email and click the link to activate your account.',
  no_activation_email: 'If you have not received the activation email, you can click this button',
  still_no_activation_email: 'You can also click this button',
  resend_activation_email: 'Resend activation email',
  try_another_way: 'Try another registration method',
  already_activated: 'If you have already activated, please click this button',

  operation: 'Operation',
  delete: 'Delete',
  done: 'Done',
  nothing_in_trashbin: 'Nothing in Trashbin',
  delete_all: 'Empty Trashbin',
  restore: 'Restore',
  confirm_title: 'Confirm',
  confirm_delete_content: 'Are you sure to delete? ',
  confirm_delete_doc: 'Are you sure to delete this item? Delete from trashbin has no way to find it back.',
  confirm: 'Confirm',
  cancel: 'Cancel',
  release_to_delete: 'Release to put file to trashbin',
  remove: 'Remove',
  title_input: 'Please input title/topic for AI assistant',

  drag: 'Drag',
  to_move: 'to move',
  click: 'Click',
  to_open_menu: 'to open menu',

  member: 'Members',
  member_tooltip: 'Manage members here, invite new members and manage their permissions',
  group: 'Groups',
  group_tooltip: 'Manage groups here, create new groups and manage its group members',
  guest: 'Guests',
  guest_tooltip: 'Guest are not members of the workspace, but can access the pages you shared with them',
  user: 'User',
  role: 'Role',
  admin: 'Admin',
  invite: 'Invite',
  add_user: 'Add Member',
  invite_user: 'Invite Members',
  add_group: 'Add Group',
  leave_team: 'Leave Team',
  remove_from_team: 'Remove from Team',
  remove_self_from_org: 'Are you sure to leave this workspace? If you need to rejoin, please contact the administrator.',
  remove_last_one_from_org: 'You are the only member of the current workspace, are you sure you want to exit? If you exit this space, you will no longer be able to enter.',
  change_role_to_member: 'Are you sure to change your role from Admin to Member?',
  last_admin_in_org: 'You are the last Admin of this workspace, can not change your role to Member.',
  size: 'Size',
  workspace_name: 'Space Name',

  to_read: 'To Read',
  read: 'Read',
  note: 'Note',
  copy: 'Copy',
  copy_url: 'Copy url',

  new_document_workspace: 'New Workspace Page/Database/Slides',
  new_document_private: 'New Private Page/DataBase/Slides',
  add_ril_tooltip: 'Add links copied from other Apps or websites to Read It Later',
  ril_tooltip: 'Articles from other Apps or websites',

  doc_not_found: `This page is not exist now. Perhaps it's been deleted forever.`,
  doc_in_trashbin: 'This page is in trashbin, do you want to restore it?',

  no_access: 'No Access',
  no_access_notice: `You're not authorized to access this page.`,
  no_access_guide: 'You can contact the owner/admin of this page to request access.',

  invited_to_teams: 'You are invited to join the following teams:',
  create_or_enter_space: 'Create new workspace or enter your workspace',
  create_or_join_space: 'Create or join workspace',
  enter_space: 'Enter my workspace',
  create_space: 'Create workspace',
  create_space_title: 'You are about to use FunBlocks for:',
  personal: 'Personal',
  team: 'Team',
  personal_desc: 'FunBlocks will help you build your knowledge space, collect and build knowledge library, and use innovative writing tools to create knowledge.',
  team_desc: 'FunBlocks provides a perfect workspace for team to work together, collaborate and output results. ',
  create_team_workspace: 'Create Team Workspace',
  create_team_workspace_detail: 'Fill in team information to make team members easy to recognize',
  team_name: 'Team or Organization Name',
  select_person: 'Select a Person',

  accept: 'Accept',
  reject: 'Reject',

  propertyPrefix: 'Property',

  color: 'Color',
  text_color: 'Text Color',
  bg_color: 'Background Color',
  highlight_color: 'Highlight Color',
  default: 'Default',

  cmd_clear_marks: 'Turn into Text',
  turn_into: 'Turn into',
  paragraph: 'Paragraph',

  basic_blocks: 'Basic blocks',
  advanced_blocks: 'Advanced blocks',
  media: 'Media',
  inline: 'Inline',

  cmd_trigger: 'is what I want',
  cmd_ai_optimize: 'AI optimize text',
  cmd_ai_continue: 'AI continue write',
  cmd_ai_assistant: 'Ask AI...',
  cmd_h1: 'Heading 1',
  cmd_h2: 'Heading 2',
  cmd_h3: 'Heading 3',
  cmd_blockquote: 'Block Quote',
  cmd_new_document: 'Page',
  cmd_link_to_page: 'Link to Page',
  cmd_new_xslides: 'Slides - Full page',
  cmd_new_flow: 'Flow',
  cmd_new_xslides_inline: 'Slides - Embed',
  cmd_new_db: 'Database - Full page',
  cmd_new_db_inline: 'Database - Embed',
  cmd_new_db_view_table: 'Table View',
  cmd_new_db_view_board: 'Board View',
  cmd_new_db_view_list: 'List View',
  cmd_new_db_view_gallery: 'Gallery View',
  cmd_new_db_view_timeline: 'Timeline View',
  cmd_image: 'Image',
  cmd_image_link: 'Image Link',
  cmd_media_embed: 'Media',
  cmd_link: 'Link',
  cmd_codeblock: 'Code Block',
  cmd_codeline: 'Code Line',
  cmd_mathblock: 'Block equation',
  cmd_mathline: 'Inline equation',
  cmd_hr: 'Horizontal Rule',
  cmd_line_break: 'Line Break/Soft return',
  cmd_block_below: 'Break',
  cmd_block_above: 'Break above',
  cmd_highlight: 'Highlight',
  cmd_superscript: 'Superscript',
  cmd_subscript: 'Subscript',
  cmd_indent: 'Indent',
  cmd_outdent: 'Outdent',
  cmd_bulleted_list: 'Bulleted list',
  cmd_ordered_list: 'Ordered list',
  cmd_todo_list: 'To-do list',
  cmd_align_left: 'Align Left',
  cmd_align_center: 'Align Center',
  cmd_align_right: 'Align Right',
  cmd_align_justify: 'Align Justify',
  cmd_table: 'Table',
  cmd_table_row: 'Table Row',
  cmd_table_column: 'Table Column',
  cmd_table_delete_row: 'Delete Table Row',
  cmd_table_delete_column: 'Delete Table Column',
  cmd_table_delete: 'Delete Table',
  cmd_table_header: 'Set Table Header',
  cmd_emoji: 'Emoji',
  cmd_hslide: 'New Horizontal Slide',
  cmd_vslide: 'New Vertical Slide',
  cmd_notes: 'Speaker Notes',
  cmd_slidecolor: 'Slide Text Color',
  cmd_background: 'Image background',
  cmd_fullscreen: 'Image fullscreen',
  cmd_fullscreencover: 'Image fullscreen',
  cmd_chart: 'Chart',
  cmd_poll: 'Poll',

  cmd_trigger_desc: 'Continue with text',
  cmd_ai_optimize_desc: 'AI optimize text',
  cmd_ai_continue_desc: 'AI continues to write based on the above content',
  cmd_ai_assistant_desc: 'Review, edit, or write',
  cmd_h1_desc: 'Big section heading',
  cmd_h2_desc: 'Medium section heading',
  cmd_h3_desc: 'Small section heading',
  cmd_blockquote_desc: 'Capture a quote',
  cmd_new_document_desc: 'Embed a sub-page inside this page',
  cmd_link_to_page_desc: 'Link to an existring page',
  cmd_new_xslides_desc: 'Add a new Slides as a sub-page',
  cmd_new_xslides_inline_desc: 'Embed a Slides inside this page',
  cmd_new_flow_desc: 'Add a new Flow as a sub-page',
  cmd_new_db_desc: 'Add a new database as a sub-page',
  cmd_new_db_inline_desc: 'Add a new inline database to this page',
  cmd_new_db_view_table_desc: 'Create a database table view',
  cmd_new_db_view_board_desc: 'Create a database board view',
  cmd_new_db_view_list_desc: 'Create a database list view',
  cmd_new_db_view_timeline_desc: 'Create a database timeline view',
  cmd_new_db_view_gallery_desc: 'Create a database gallery view',

  cmd_image_desc: 'Upload an image',
  cmd_image_link_desc: 'Embed image with a link',
  cmd_media_embed_desc: 'Embed a video/audio with a link',
  cmd_link_desc: 'Add a link',
  cmd_codeblock_desc: 'Capture a code snippet',
  cmd_codeline_desc: 'Capture a code line',
  cmd_mathblock_desc: 'Display a standalone math equation',
  cmd_mathline_desc: 'Insert mathematic symbols in text',
  cmd_hr_desc: 'Visually divide blocks',
  cmd_line_break_desc: ' (Shift + Enter)',
  cmd_block_below_desc: 'Insert a block below (Ctrl/⌘ + Enter)',
  cmd_block_above_desc: 'Insert a block above (Ctrl/⌘ + Shift + Enter)',
  cmd_highlight_desc: 'Mark text with a color',
  cmd_superscript_desc: 'Display text as superscript',
  cmd_subscript_desc: 'Display text as subscript',
  cmd_indent_desc: 'Increase indentation',
  cmd_outdent_desc: 'Decrease indentation',
  cmd_bulleted_list_desc: 'Create a simple bulleted list',
  cmd_ordered_list_desc: 'Create a simple numbered list',
  cmd_todo_list_desc: 'Track tasks with a to-do list',
  cmd_align_left_desc: 'Align text to the left',
  cmd_align_center_desc: 'Align text to the center',
  cmd_align_right_desc: 'Align text to the right',
  cmd_align_justify_desc: 'Justify text',
  cmd_table_desc: 'Add a simple table to the page',
  cmd_table_row_desc: 'Insert a table row',
  cmd_table_column_desc: 'Insert a table column',
  cmd_table_delete_row_desc: 'Delete table row',
  cmd_table_delete_column_desc: 'Delete table column',
  cmd_table_delete_desc: 'Delete this table and all its data',
  cmd_table_header_desc: 'Set current row as table header',
  cmd_emoji_desc: 'Select and insert Emoji',
  cmd_bold_desc: 'Bold',
  cmd_italic_desc: 'Italic',
  cmd_copy_desc: 'Copy',
  cmd_strikethrough_desc: 'Strike through',
  cmd_hslide_desc: 'Add horizontal slide separator',
  cmd_vslide_desc: 'Add vertical slide separator',
  cmd_notes_desc: 'Add speaker notes',
  cmd_slidecolor_desc: 'Set current slide text color',
  cmd_background_desc: 'Set image as slides background',
  cmd_fullscreen_desc: 'Set image fullscreen',
  cmd_fullscreencover_desc: 'Set image fullscreen cover',
  cmd_chart_desc: 'Add chart with csv data',
  cmd_poll_desc: 'Add poll/test for online seminar',
  cmd_iframe_desc: 'Embed web page to slide',
  cmd_iframe: 'Embed web page',

  missing_required_data: 'Missing required info',
  missing_one_data: 'Please input at least one piece of information.',

  text: 'Text',
  alt_text: 'Caption',
  link: 'Url',
  upload_file: 'Upload File',
  upload_failed: 'Upload failed, please try again later',
  poll_desc: 'Poll description',
  chart_type: 'Result chart type',
  no_enough_options: 'No enough options',
  poll_responses: 'Responses',
  poll_result: 'Poll Result',
  data_source: 'Data source',
  csv_data: 'CSV Data',
  csv_file: 'CSV File',
  csv_url: 'CSV Url',
  rows: 'Rows',
  columns: 'Columns',
  show_csv_example: 'Show CSV data sample',
  data_invalid: 'Data invalid',
  doc_saved: 'Doc Saved!',
  doc_save_failed: 'Failed to save doc',
  save_shortcut: 'To save doc: Ctrl/Cmd + S',
  editor_slash_hint: `Type '/' for commands`,

  askAI: 'Choose an AI task below, or tell AI how to handle the selected content...',
  askAI_next: 'Choose an action below, or tell AI what to do next...',
  askAI_doing: 'AI is writing ',
  draft_placeholder: 'Please enter the subject and specific requirements (such as word count, main points, etc.)...',
  fill_in_selected: 'Fill in selected text',
  sendAI: 'Ask AI',
  submitAI: 'Confirm',
  reset_input: 'Reset',
  draft_artile: 'Write a',
  topic_label: 'about',
  outline_prompt: 'You are going to write an {article} with the subject: ```{topic}```, key points: ```{key_points}```. Please draw up a clear outline',
  draft_more_type: 'Article type',

  selection_text: 'Selection text:',
  ai_response: 'AI wrote:',

  copy_generated_content: 'Copy',
  replace_selection: 'Accept',
  replace_title: 'Replace title',
  insert_title: 'Insert title',
  insert_below: 'Insert below',
  replace: 'Replace',
  insert: 'Insert',
  continue_writing: 'Continue writing...',
  make_longer: 'Make longer',
  try_again: 'Try again',
  discard: 'Discard',
  confirm_close_ai: 'Are you sure to close AI assistant? The unsaved AI generated content will be discarded',
  confirm_discard_ai_content: 'Are you sure to step back? The unsaved AI generated content will be discarded',
  confirm_no_wait: `The AI assistant is writing, are you sure you won't have to wait a while?`,
  text_too_long: 'The selection text is too long',
  no_text: 'No content for AI',
  ai_timeout: 'AI timeout',
  should_select_item: 'Please select item from action menu below',
  should_text_or_select_item: 'Please select item from action menu below or text to ask AI directly',
  ai_response_warning: 'AI response can be inaccurate or misleading',

  bold: 'Bold',
  italic: 'Italic',
  underline: 'Underline',
  strikethrough: 'Strike through',
  comment: 'Comment',
  keyboard: 'Keyboard',
  align: 'Align',

  editor_placeholder_title: 'Untitled',
  editor_placeholder_p: `Type '/' for commands`,

  create_new_db_view: 'Create New Database View',
  create_new_slides: 'Create New Slides',
  choose_existing_slides: 'Choose Existing Slides',
  page_chooser_title: 'Choose ',
  choose_page_to_link: 'Choose a page to link',
  editor_new_page_desc: 'Press Enter to continue with an empty page or click to choose a specific typed page',
  data_editor_new_page_desc: 'Click here to create a new empty page',
  empty_page: 'Empty',
  empty_page_with_ai: `Write "{title}" with AI`,
  empty_page_ai_prompt: `Write about {topic}`,
  empty_slides_with_ai: `Write slides "{title}" with AI`,
  empty_slides_ai_prompt: `Write a slides about {topic}`,
  db_section_title: 'Database',
  slides_section_title: 'Slides',
  flow_section_title: 'AI Whiteboard + Mindmap',

  slide_seminar_tooltip: 'Online seminars, remote synchronous interaction, support lecturer chalk, whiteboard explanation, question interaction, etc.',
  slide_present_tooltip: 'Watch/Present Slides',
  slide_edit_tooltip: 'Edit Slides',
  slides_tutor_tooltip: 'Slides edit tutorial',

  sorry_for_uninstall: "We're sorry that you've uninstalled our extension",
  please_tell_your_uninstall_reason: "To help us improve, please tell us the reason for uninstalling this extension.",
  thanks_for_feedback: "Thank you for your feedback; we will take it seriously. We hope you'll consider using our extension again!",
  please_give_reason: "Please tell us the main reason for uninstalling this extension.",
  please_give_detail: "Please provide us with your valuable suggestions and information.",
  submit: "Submit",

  install_funblocks_ai_extension: 'Install the FunBlocks AI browser extension to summon the AI assistant anytime, boosting your work and study efficiency.',
  intro_extension: 'The FunBlocks AI browser extension utilizes cutting-edge AI models (such as GPT-4, Gemini, Claude, etc.) to achieve "10x speed, high quality" in: reading, writing, social interaction, email summarization and reply, video content summarization, and more.',
  intro_funblocks: 'At the same time, FunBlocks not only offers browser plugin tools but also serves as a more comprehensive all-in-one AI platform compared to Notion. With an editor as the interface, it allows you to collaborate with AI assistants, thereby enhancing writing and learning efficiency. Give it a try!',
  copy_invite_link: 'Copy the invitation link',
  invitation_records: 'Invitation Record',
  click_to_copy_msg_link: 'Click to Copy Link & Message',
  download_extension_to_participate: 'Download Extension for free',
  welcome_to_funblocks: 'Welcome to FunBlocks AI',
  invite_to_earn: `Invite to Earn!
    Get {coins} free AI tokens for each invite
    `,
  invited_to_given: 'Invited users will also receive {coins} AI tokens',
  free_coins_awarded: 'Download now to get an extra {coins} free AI queries',
  detail: 'Detail',
  invited_frends: 'Invited Friends',
  total_rewards: 'AI Queries Reward',
  greetings_logon: "Welcome to FunBlocks AI! You are now logged in.",
  friend: 'Friend',
  reward: 'Reward',
  time: 'Time',
  edit_image: 'Edit Image',
  flow_edit_image_placeholder: 'Describe how you want to modify the image...',
  node_action_edit_image: 'Edit Image',
  generate_image: 'Generate image',
  generate_image_tips: 'Generate image',
  flow_generate_image_placeholder: 'Describe the image you envision...',
  node_action_generate_image: 'Generate image',

  flow_ask_placeholder: 'Ask anything...',
  flow_ask_question_placeholder: 'Ask question...',
  flow_ask_question_or_choose_skill_placeholder: 'Ask question or choose AI skill...',
  flow_dynamic_placeholder: 'Type your requirements...',
  flow_dynamic_with_menu_placeholder: 'Type requirements, or select prompt below...',
  flow_breakdown_placeholder: 'Type any topic to breakdown...',
  flow_brainstorming_placeholder: 'Enter theme to start brainstorming...',
  flow_brainstorming_decision_analysis_placeholder: 'Enter the decision you want to analyze...',
  flow_link_placeholder: 'Paste an url to explore its content...',
  flow_video_placeholder: 'Paste youtube video link to explore its content...',
  flow_search_placeholder: 'Search latest information from web...',
  flow_perspective_placeholder: 'Enter new perspective...',
  flow_idea_placeholder: 'Enter new idea...',
  flow_task_analysis_placeholder: 'Click to Analysis Task...',
  flow_new_task_placeholder: 'Enter new task description...',
  flow_todos_placeholder: 'Enter your task, goal or project...',
  flow_new_sub_topic_placeholder: 'Enter new subtopic...',
  flow_new_insight_placeholder: 'Enter new insight...',
  flow_mindmap_primary_branch_placeholder: 'Enter key concept/point...',
  flow_add_improve_plan_placeholder: 'Enter new improvement plan...',
  flow_book_mindmap_primary_branch_placeholder: 'Enter topic...',
  flow_movie_mindmap_primary_branch_placeholder: 'Enter topic...',
  flow_image_mindmap_primary_branch_placeholder: 'Enter perspective...',
  flow_book_placeholder: 'Enter book title...',
  flow_movie_placeholder: 'Enter movie name...',
  flow_rationale_placeholder: 'Enter the decision you need to make...',
  flow_dreamlens_placeholder: 'Describe your dream, as detailed as possible...',
  new_perspective: 'New perspective',
  new_idea: 'Add new idea',
  new_task: 'Add new task',
  remove_task: 'Delete task',
  new_sub_topic: 'Add new subtopic',
  new_insight: 'Add new insight',
  mindmap: 'Mindmap',
  mindmap_primary_branch: 'Generate a new perspective for the given text in the mind map',
  book_mindmap_primary_branch: 'Generate a new perspective for the book in the mind map',
  movie_mindmap_primary_branch: 'Generate a new perspective for the movie in the mind map',
  image_mindmap_primary_branch: 'Generate a new perspective for the image in the mind map',
  generate_mindmap: 'Generate a mindmap',
  summarize_tips: 'Generate a summary',
  summarize: 'Summarize',
  summary: 'Summary',
  more_ai_menu: 'More AI actions',
  graphics: 'Graphics',
  rationale: 'Decision Rationale',

  click_to_execute: 'Click to {action}',
  node_action_dynamic: 'run prompt',
  node_action_ask: 'ask question',
  node_action_brainstorming: 'brainstorm',
  node_action_breakdown: 'breakdown',
  node_action_todos: 'generate todo list',
  node_action_search: 'search web',
  node_action_link: 'summarize web page',
  node_action_video: 'add video',
  node_action_book: 'generate mindmap',
  node_action_movie: 'generate mindmap',
  node_action_rationale: 'generate rationales',
  node_action_dreamlens: 'interpret dreams',
  node_action_dynamic_with_menu: 'run prompt',
  node_action_ask_question: 'ask question',
  node_action_mindmap_primary_branch: 'add perspective',
  node_action_idea: 'add idea',
  node_action_perspective: 'add perspective',
  node_action_new_sub_topic: 'add new sub topic',
  node_action_new_insight: 'add new insight',
  node_action_book_mindmap_primary_branch: 'add new topic',
  node_action_movie_mindmap_primary_branch: 'add new topic',
  node_action_image_mindmap_primary_branch: 'add new perspective',
  node_action_add_improve_plan: 'Add improvement instruction',

  toggle_options: 'Toggle options',
  change_prompt_querytype: 'Click to switch AI mode',

  describe_image_tooltip: 'Explore image with mindmap',
  describe_image_witty_tooltip: 'See the world from a fresh perspective',
  expand_ideas: 'Ideation',
  breakdown_topics: 'Breakdown Topics',
  breakdown_topics_tips: 'Break complex topics into subtopics',
  ask_question: 'Ask a Question',
  ask_question_tips: 'Ask a question, AI helps you optimize the question',
  dynamic: 'Do something',
  dynamic_tips: 'Input your prompt, AI will help clarify your needs before proceeding',
  brainstorming: 'Brainstorming',
  brainstorming_tips: 'Explore or generate ideas on any topic with mind mapping',
  todo_list: 'To-Do list',
  empty_todo_list: 'Create a new To-Do list',
  empty_todo_list_tips: 'Add your first To-Do item by clicking the button below',
  generate_todo_list: 'Generate To-Do list',
  generate_todo_list_tips: 'Task breakdown, generate a To-Do list',
  generate_book_mindmap: 'Explore book with mind mapping',
  generate_movie_mindmap: 'Explore movie with mind mapping',
  search_web: 'Search the web',
  rationale_decision: 'Make rational decisions',
  dreamlens_tips: 'Analysis dream with multi-perspective psychological insights, helping you unlock your subconscious',
  explain: 'Explain',
  selectable_context: 'Context',
  add_note: 'Add a note',
  add_image: 'Add an image',
  add_link: 'Add a link',
  add_video: 'Add video',
  add_caption: 'Add caption',
  to_note: 'Copy to editable sticky note',
  as_note: 'convert to sticky note',
  to_todolist: 'Copy to To-do list',
  copy_node: 'Copy node',
  save_to_workspace: 'Save as a document in your workspace, ready for further AI Assistant editing',
  export_to_pdf: 'Export to PDF',
  paste_as_node: 'Paste',
  type_note: 'Type a note',
  group_nodes: 'Group the selected nodes',
  ungroup_nodes: 'Ungroup',
  remove_from_group: 'Move node out of this group',
  redo: 'Redo',
  undo: 'Undo',
  export_to_image: 'Export as image. Share your exported images with friends and earn free AI access tokens!',
  add_private_llm_key: 'Add Private LLM API Key',
  set_ai_model: 'Set LLM model',
  enable_ai_use_search: 'Enable search the web',
  disable_ai_use_search: 'Disable search the web',
  set_default_ai_model: 'Set default LLM model',
  get_models_test_token: 'Get Models/Test Key',
  token_valid: 'Key and Endpoint valid',
  token_invalid: 'Key or Endpoint invalid',
  set_lang_as_context: 'Context-adaptive',
  set_ai_response_lang: 'Set AI response language',
  tell_me_more: 'Explore further...',
  selectable_ideas: 'Selectable ideas',
  hide_action_box: 'Hide',
  get_more: "Load more",
  remove_node: 'Remove node',
  expand: 'Expand',
  collapse: 'Collapse',
  search_results: 'Search engine results',
  query_analysis: 'Analysis of the original question',
  optimized_query: 'Optimized questions',
  show_detail: 'Show detail',
  hide_detail: 'Hide detail',

  video_no_transcripts: 'No transcripts for the video',

  new_board: 'Create new board',
  edit_title_placeholder: 'Edit title',
  please_set_ai: 'Please login AI Flow or Setup 3rd party LLM Service provider',
  please_set_api: 'You have selected a third-party LLM service provider, but the API parameters are not set correctly.',
  exceed_msg_limit: `You've reached your daily {model_level} LLM limit. Please upgrade your FunBlocks AI plan for a higher quota or choose another model to continue.`,
  no_aigc_content: 'AI failed to generate content.',
  copy_to_edit: 'Create Editable Copy',
  flow_info_login: 'Please log in',
  flow_info_extension_not_supported_for_current_browser: 'Please use Chrome or Edge browser to install the FunBlock AI Extension.',
  flow_info_copy_to_editable: 'The current whiteboard is read-only. To make changes, please copy an editable version.',
  flow_info_saved_to_workspace: "Content saved to your workspace. Click the button below to continue editing.",
  to_workspace: 'Continue Editing',
  copy_to_editable: 'Copy',
  flow_settings: 'Settings',
  settings_funblocks_desc:
    'After registering a FunBlock account, you will receive a trial of 40 queries to use the AI assistant and enjoy 20 free AI assistant queries every day. Additionally, you have the option to upgrade to the FunBlock AI membership plan for unlimited access to the AI assistant.',
  invalid_api_for_image: 'AI Assistant does not currently support querying image information from third-party LLM services. Please select FunBlocks AI service.',
  ai_insights: 'Generate exploration space',
  summuary_insights: 'Summarized insights',

  cut: 'Cut',
  paste: 'Paste',
  paste_as_plain_text: 'Paste as plain text',
  funblocks_aiflow: 'FunBlocks AIFlow',
  aiflow_slogan: 'Work, Learn and Explore with',
  qr_code_tips: 'Scan the QR Code to continue exploring ...',
  share_tip: 'Share your exciting AI exploration with friends and get free AI access.',
  ai_token: 'AI Token',
  ai_token_desc: 'Standard AI queries cost 1 AI token, advanced AI queries cost 5 AI tokens',
  your_ai_token_balance: 'You AI Token Balance: ',
  ai_tokens_income: 'AI Token Income Transations',
  show_latest_records: 'Only show the latest {count} items',
  wanna_more_ai_tokens: 'Get more AI tokens: ',
  date: 'Date',
  count: 'Amount',
  income_item: 'Income Item',
  new_user_trial: 'Trial',
  invited_by_friend: 'Invited By Friend Reward',
  invite_friend: 'Invite Friend Reward',
  shared_aigc_visited: 'Shared AIGC Visited Reward',
  share_aigc: 'Share AI Flow works with friends or community',
  share_aigc_tips: 'Each person who reads the AIFlow work through your shared link will earn you 10 AI Tokens as a reward. You can share the link by clicking the Share button or export the work as an image before sharing.',
  choose_flow_board: 'Switch board',
  create_flow_board: 'Create new board',
  priority: 'Priority',
  due_date: 'Due date',
  breakdown_task: 'Break down into subtasks',
  copy_content: 'Copy content to clipboard',
  save_page: 'Save',

  advanced_settings: 'More settings',
  type_or_select_placeholder: 'Type or select from the right...',

  topic_type: 'Scenario',
  thematic_learning: "Thematic Learning",
  personal_growth: "Personal Growth",
  startup_idea: "Startup Idea",
  bussiness_analysis: "Business Analysis",
  investment_analysis: "Investment Analysis",
  product_innovation: "Product Innovation",
  solutions_design: "Solutions Design",
  project_planning: "Project Planning",
  marketing_strategies: "Marketing Strategies",
  branding_building: "Branding Building",
  advertisiing_compaign_ideas: "Advertising Campaign Ideas",
  root_cause_analysis: "Root Cause Analysis",
  team_management: "Team Management",
  operation_optimization: "Operation Optimization",
  innovative_ideas: "Generate Innovative Ideas",
  event_planning: "Event Planning",
  decision_analysis: "Decision Analysis",
  meeting_disucssions: "Meeting Discussions",
  travel_experiences: "Travel Experiences",
  writing_ideas: "Develop a writing framework",
  research_topics: "Research Topics",
  curriculum_design: "Curriculum Design",
  social_issue_solutions: "Social Issue Solutions",

  thinking_model: 'Thinking model',
  swot_analysis: "SWOT Analysis",
  first_principle: "First Principle",
  business_model_canvas: "Business Model Canvas",
  fivew1h_method: "5W1H Method",
  scamper_method: "SCAMPER Method",
  six_thinking_hats: "Six Thinking Hats",
  pdca: "PDCA Cycle",
  systems_thinking: "Systems Thinking",
  steep_analysis: "STEEP Analysis",
  five_forces: "Five Forces Analysis",
  four_p: "4P Marketing Mix",
  triz: "Theory of Inventive Problem Solving",
  rephrazing: "Problem Rephrasing",
  learning_pyramid: "Learning Pyramid",
  kwl: "KWL Map",
  changing_perspectives: "Changing Perspectives",
  reverse_thinking: "Reverse Thinking",
  role_playing: "Role Playing",
  mckinsey_7S_framework: "McKinsey 7S Framework",
  value_proposition_canvas: "Value Proposition Canvas",
  pros_cons: 'Pros and Cons',
  decision_tree: 'Decision Tree',
  decision_matrix: 'Decision Matrix',
  cost_benefit_analysis: 'Cost-Benefit Analysis',
  casual_chain: 'Casual Chain',

  payment: "Payment",
  flow_exported_image_watermark_qrcode: "Included QR code in the downloaded AI Flow image",
  flow_exported_image_watermark_qrcode_tips: "The QR code contains your invitation code. If users visit the link or register through it, you will receive corresponding AI Token rewards",

  selected_node: 'Only parent node',
  selected_node_and_descendants: "Parent node and its descendants",
  grouped_nodes: 'Grouped nodes',
  reflect_and_improve: 'Reflect and improve',
  reflect: 'Reflect',
  improvement_plan: 'Improvement plan',
  improve_now: 'Improve now',
  add_improvement_plan: 'Add Item',
  failed_load_image: 'Failed to get image data',

  more_info_form: 'Please provide more information',
  generate_content_format: 'Content format',
  ai_infered_task_type_description: 'The AI infers the task type from the description, and subsequent information depends on this. If incorrect, please reselect the task type and click the right button to retry.',
  underlying_needs: 'Underlying Goal/Needs',
  softbreak_tips: "Use 'Shift + Enter' for new line",
  "keyboard_save_hint": "Control/Command + s: Save board",
  "paste_to_board_hint": "You can paste text, links, and images directly onto the whiteboard.",
  'flow_settings_mouse_touch': 'Mouse and Touch Settings',
  'flow_settings_drag_behavior': 'Drag Behavior',
  'flow_settings_drag_pan': 'Drag to Pan Canvas',
  'flow_settings_drag_select': 'Drag to Select Nodes',
  'flow_settings_scroll_behavior': 'Scroll Behavior',
  'flow_settings_scroll_zoom': 'Scroll to Zoom Canvas',
  'flow_settings_scroll_pan': 'Scroll to Pan Canvas',
  'flow_settings_pinch_zoom': 'Enable Pinch to Zoom',
  'flow_settings_key_mouse_combo': 'Keyboard and Mouse Combinations',
  'flow_settings_selection_key': 'Selection Key',
  'flow_settings_selection_hint': '{key} + Drag: Select Nodes',
  'flow_settings_pan_key': 'Pan Key',
  'flow_settings_pan_hint': '{key} + Drag: Pan Canvas',
  'flow_settings_zoom_key': 'Zoom Key',
  'flow_settings_zoom_hint': '{key} + Scroll: Zoom Canvas',
  'flow_settings_delete_node': 'Enable Backspace to Delete Node',
  'flow_settings_scroll_pan_horizontal_hint': 'Shift + Scroll: Pan Horizontal',
  'pinch_zoom_tip': 'Pinch: Zoom Canvas',

  "flow-editor": "Welcome to FunBlocks AIFlow!",
  "flow-boards": "View or Create Whiteboards",
  "dynamic-ai-nodes": "Start AI Interaction",
  "more-ai-nodes": "More AI Interaction Nodes",
  "non-ai-nodes": "Notes, To-Do Lists",
  "model-selector": "Select AI Model",
  "output-lng-selector": "Set AI Output Language",
  "board-operations": "Save, Undo, Redo",
  "share-board": "Export or Share Whiteboard, Earn Free AI Access Rewards",
  "download-extension": "Download FunBlocks AI Browser Extension to Boost Your Work and Study Efficiency",
  "settings": "App Settings",
  "board-viewport-operations": "Adjust Whiteboard View",
  "board-interaction-hint": "Whiteboard Mouse/Keyboard Hint",
  "minimap": "Minimap Navigation",
  temp_notesbook: "Scrapbook",
  to_temp_notebook: 'Add to scrapbook',
  merge_notes: 'Merge clippings',
  select_all: 'Select all',
  deselect_all: 'Deselect all',
  delete_selected_clips: 'Delete selected clips',
  regenerate_tooltip: 'Will generate a new node with the selected model',
  generated_with: 'Generated by',
  regenerate_with: 'You can regenerate using',
  save_to_prompt: 'Save prompt',
  space_intro: 'FunBlocks Workspace',
  to_workspace: 'To Workspace',
  what_is_workspace: 'What is FunBlocks Workspace?',
  workspace_intro: `FunBlocks Workspace is an all-in-one knowledge hub where you can create, organize, and collaborate on documents, presentations, and mind maps.  Powered by an AI assistant that learns and adapts to your needs, the workspace becomes your personalized knowledge base, boosting your productivity and streamlining creative workflows.`,
  generate_slides: 'Generate Presentation',
  node_export: 'Export',
  share_prompt_title: 'Share an amazing FunBlocks AI Prompt application: {prompt}. Open this link and click "Run" to let the AI assistant work its magic for you!',
  add_promo_code: 'Add promotion code',
  enter_promo_code: 'Promotion code',
  apply: 'Apply',
  layout_boards: 'Automatic node arrangement',
  optimize_question: 'Optimize question',
  optimize_search: 'Optimize search keywords',
  to_original_page: 'To original page',
  add_funblocks_page: 'Add a FunBlocks page or slides',
  page_chooser_title: 'Choose a FunBlocks page or slides',
  email_opt_in: 'I agree to receive application-related emails',
  receive_update_emails: 'Receive application update emails',
  receive_update_emails_tips: 'You will receive emails about new features and important updates.',
  flow_info_action_requires_specific_models: 'This action requires specific models: {models}. Please switch to the corresponding model.',
  action_requires_specific_models: 'This action requires specific models: {models}. Press confirm to switch model to: {switch_to_model}',
  resize_proportionally: 'Resize proportionally',
  infograph: 'Infograph',
  infograph_tips: 'Generate Flowchart, Slide, Cornell notes, or Infographics from node content',
  all_hints: 'All Hints',
  show_all_hints: 'Show All Hints',
  'flow_settings_show_examples': 'Show input examples above the input box',
  'flow_settings_show_hint_panel': 'Show interaction hint on bottom of whiteboard',
  choose_image: 'Choose image',
  paste_image_url: "Paste image url",
  or: 'or',
  zoom_in: 'Zoom in',
  zoom_out: 'Zoom out',
  fullscreen: 'Full Screen',
  search_grounding: 'Search queries',
  more_tools: 'More tools'
};
